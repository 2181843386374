import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Row, Card, Button, Col, InputGroup, FormControl, CardGroup, Modal, ButtonGroup, CardDeck, Image, Carousel, CarouselItem, ModalDialog, ModalBody} from 'react-bootstrap';
import Navigation from './Navigation';
import Footer from './Footer';
import tasteMakersLogo from './Tastemakers Basic Logo.png'
import { App } from '@capacitor/app';
import { useNavigate, Link } from 'react-router-dom';
import editButton from './editPen.png'
import deleteButton from './deleteButton.png'
import reportButton from './report dark.png'
import ReportModal from './ReportModal'
import debounce from 'lodash.debounce';


export default function PostSpecific(){
    // Get the current URL
    // const currentUrl = process.env.REACT_APP_FRONTEND_URL;

    // // Split the URL by '/'
    // const urlParts = currentUrl.split('/');

    // // Find the index of 'User' and 'Post' in the URL
    // const userIndex = urlParts.indexOf('User');
    // const postIndex = urlParts.indexOf('Post');

    // // Extract userId and postId based on their positions in the URL
    // const userId = urlParts[userIndex + 1];
    // const postId = urlParts[postIndex + 1];
    const location = useLocation();
    const { userId, postId } = location.state || {};

    const currentUserId = sessionStorage.getItem('userId')
    const userImg = sessionStorage.getItem("imgURL");

    //console.log("User ID:", userId);
    //console.log("Post ID:", postId);

    const [handleState, setHandleState ]= useState(false);
    const [post, setPost] = useState([]);
    const [comments,setComments] = useState([]);
    const [isLiked, setIsLiked] = useState(false);
    const [likes, setLikes] = useState(0);
    const [backendUser, setBackendUser] = useState([]);
    const [spotifyUser, setSpotifyUser] = useState([]);
    const [isUpdatingPost, setIsUpdatingPost] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null);
    const [postContent, setPostContent] = useState('');
    const [postImageUrl, setPostImageUrl] = useState('');
    const userEmail = sessionStorage.getItem("email")
    
    const [inputValue, setInputValue] = useState('');
    
    const [complaint, setComplaint] = useState('');  // State to store input value
    const navigate = useNavigate();

    const [showTextarea, setShowTextarea] = useState(false);

    const handleShowTextarea = () => {
        setShowTextarea(true);
    };

    const handleHideTextarea = () => {
        setShowTextarea(false);
    };
    const [showModal, setShowModal] = useState(false);

    const handleOpenReportModal = () => {
        setShowModal(true);
    };
    const updateComplaint = debounce((value) => {
        setComplaint(value);
      }, 300); 

    const handleCloseReportModal = () => {
        setComplaint('')
        setInputValue('')
        setShowModal(false);
    };

    const submitPostReportDetail = async (ownerId, reportPostId, reportPostUserId, complaint) => {
        handleCloseReportModal();  // Close the modal
        // alert(ownerId + " " + reportPostUserId + " " + reportPostId + " " + complaint)
        try {
            const reportResponse = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/posts/reportPost', {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    postId: reportPostId,
                    userId: ownerId,
                    reportedUserId: reportPostUserId,
                    message: complaint
                })
            });
    
            // Check for network errors
        if (!reportResponse.ok) {
            // Try to parse the response as JSON
            let errorMessage = 'An error occurred';  // Default error message
        
            try {
                const errorResponse = await reportResponse.json();  // Parse response as JSON
                if (errorResponse.error) {
                    errorMessage = errorResponse.error;  // Extract the specific error message
                } else {
                    errorMessage = JSON.stringify(errorResponse);  // If no specific error, show the full response
                }
            } catch (err) {
                // If the response isn't valid JSON, fallback to plain text
                errorMessage = await reportResponse.text();  // Extract the plain text response
            }
        
            console.error('Error Response:', errorMessage);
            // alert(errorMessage);
            return;
        }
    
            // Handle successful report
            // alert("Post reported successfully.");
        } catch (error) {
            // Catch network errors or issues with fetch
            console.error('Fetch error:', error);
            // alert('Failed to fetch: ' + error.message);
        }
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);  // Update the local input value immediately
        updateComplaint(value);  // Use debounced function to update the complaint
      };
      useEffect(() => {
        return () => {
          updateComplaint.cancel();  // Cleanup the debounce function on component unmount
        };
      }, []);
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
      }, []);

      useEffect(() => {

        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        };
      
        window.addEventListener("resize", handleResize);
        handleResize(); // Set initial size on mount
      
        return () => window.removeEventListener("resize", handleResize);
        
      }, []);

    const [commentText, setCommentText] = useState(''); // State to store comment text

    useEffect(() => {
        const backButtonListener = App.addListener('backButton', (data) => {
            if (window.location.pathname === '/') {
                App.exitApp(); // Exit app if on the home page
            }else {
                  navigate(-1); // Go to the previous page
              } 
        });

        // Cleanup the listener when the component unmounts
        return () => {
            backButtonListener.remove();
        };
    }, [navigate]);

    useEffect(() => {
        async function fetchData() {
          try {
            // Fetching register user data
            const registerResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/register/${userEmail}`);
            if (!registerResponse.ok) {
              throw new Error('Failed to fetch register user');
            }
            const registerData = await registerResponse.json();
            setSpotifyUser(registerData);
            //console.log(registerData); // Debugging (remove if sensitive)
        
            // Fetching Spotify user data
            const spotifyResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + '\"' + registerData.userName + '\"', {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
        
            if (!spotifyResponse.ok) {
              throw new Error('Failed to fetch Spotify user');
            }
            const spotifyUserData = await spotifyResponse.json();
            // //console.log(spotifyUserData)
            setBackendUser(spotifyUserData);
        
          } catch (error) {
            console.error('Error fetching data:', error);
            // Handle errors appropriately
          }
        }
      
        fetchData();
      },[])

    function handleCancelButton(){
        setIsUpdatingPost(false)
    }
    const handleDeletePost = async (postId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/deletePost`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    postId: postId,
                })
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }else{
            //    alert("Post Deleted")
               navigate("/Feed")
            }
    
            // If the delete operation was successful
            // const deleteResult = await response.json();
            // //console.log(deleteResult);
    
            // Update your state to remove the deleted comment
            // For example, you might filter out the deleted comment from your comments state
            // setComments(prevComments => prevComments.filter(comment => comment._id !== commentId));
    
        } catch (error) {
            console.error('Error deleting comment:', error);
            // Handle the error appropriately in your UI
        }
    };
    useEffect(() => {
        function fetchData() {
          const checkUser = fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/getUserById/' + userId, {
            method: "GET",
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
              'Content-Type': 'application/json',
            },
          }).then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok: ' + response.status);
            }
            return response.json();
          });
      
          const checkPost = fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/getPost/' + postId, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
              'Content-Type': 'application/json',
            },
          }).then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok: ' + response.status);
            }
            return response.json();
          });
      
          Promise.all([checkUser, checkPost])
            .then(([userData, postData]) => {
              setHandleState(true);
              setPost(postData);
              setComments([...postData.comments]); // Spread to ensure a new reference
            })
            .catch(error => {
              console.error('Error fetching data:', error);
            });
        }
      
        fetchData();
      }, [isLiked, commentText, comments.length, comments.reduce((acc, comment) => acc + comment.likes.length, 0)]);      
      
       const handleCommentLike = async (postId, commentId, userId) => {
        // alert(`${postId} commentId: ${commentId} userId: ${userId}`);
        try {
          // Fetch post details to check if the comment is already liked
          const commentDataResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/getPost/${postId}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
              'Content-Type': 'application/json',
            }
          });
      
          if (!commentDataResponse.ok) {
            // alert(`Error fetching comment data: ${commentDataResponse.status}`);
            return;
          }
      
          const postData = await commentDataResponse.json();
          const comment = postData.comments.find(c => c._id === commentId);
      
          if (!comment) {
            // alert('Comment not found');
            return;
          }
      
          // Determine if the current user has already liked the comment
          const isLiked = comment.likes.some(like => like.userId === userId);
        //   alert(comment);
        //   alert(isLiked);
      
          // Choose the appropriate endpoint and HTTP method
          const endpoint = isLiked ? '/api/posts/removeLikeComment' : '/api/posts/likeComment';
          const method = isLiked ? 'DELETE' : 'POST';
        //   alert(endpoint + " " + method);
      
          // Make the like or unlike request
          const handleCommentLikeRequest = await fetch(process.env.REACT_APP_BACKEND_URL + endpoint, {
            method: method,
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              commentId: commentId,
              postId: postId,
              userId: userId
            })
          });
      
          if (!handleCommentLikeRequest.ok) {
            // alert(`Error: ${handleCommentLikeRequest.status}`);
          } else {
            const message = isLiked ? "Removed Like from Comment" : "Liked Comment Successfully";
            // alert(message);
      
            // Update local state to reflect new like status
            setComments(prevComments => 
              prevComments.map(c => 
                c._id === commentId ? 
                { ...c, likes: isLiked ? c.likes.filter(like => like.userId !== userId) : [...c.likes, { userId }] } 
                : c
              )
            );
          }
        } catch (e) {
          console.error('Error liking/unliking comment:', e);
        }
      };
      
    

      const likeOrUnlikePost = async (postId, userId) => {
        try {
            // Fetch user details to get realId
            const userRealIdResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + userId,{
                method:"GET",
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
            });
            const userData = await userRealIdResponse.json();

            if (!userData || !userData._id) {
                console.error('User data or user ID not found');
                return;
            }

            const realId = userData._id;
            //console.log('Real ID:', realId);

            // Fetch post details to check if realId is in the likePost array
            const postResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/getPost/' + postId, {
                method:"GET",
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
            });
            const postData = await postResponse.json();

            const likePostArray = postData.likes || [];
            const isLiked = likePostArray.some(like => like.userId === realId);

            if (isLiked) {
                // If already liked, unlike the post
                const unlikeResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/unLikePost/' + postId + '/user/' + realId, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                        'Content-Type': 'application/json',
                      },
                });
                const unlikeResult = await unlikeResponse.json();
                //console.log(unlikeResult);
            } else {
                // If not liked, like the post
                const likeResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/likePost/' + postId + '/user/' + realId, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                        'Content-Type': 'application/json',
                      },
                });
                const likeResult = await likeResponse.json();
                //console.log(likeResult);
            }

            // Update the state to trigger a re-render
            setIsLiked((prevIsLiked) => !prevIsLiked);

            setLikes(likePostArray.length);
        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    };

    const handlePostComment = async (postID) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/addComment`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    postId: postID,
                    userId: backendUser.userId,
                    text: commentText,
                    profileImageUrl: backendUser.profileImageUrl
                })
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const postResult = await response.json();
            //console.log(postResult);
    
            // Clear the comment text and hide the textarea
            setCommentText('');
            setShowTextarea(false);
    
            // Update comments or other related states if necessary
    
        } catch (error) {
            console.error('Error posting comment:', error);
            // Handle the error appropriately in your UI
        }
    };    

    const remainingCharacters = 200 - commentText.length;

    const handleDeleteComment = async (commentId, postId, userId, profileImageUrl) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/deleteComment`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    postId: postId,
                    userId: userId,
                    commentId: commentId,
                    profileImageUrl:profileImageUrl
                })
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            // If the delete operation was successful
            const deleteResult = await response.json();
            //console.log(deleteResult);
    
            // Update your state to remove the deleted comment
            // For example, you might filter out the deleted comment from your comments state
            setComments(prevComments => prevComments.filter(comment => comment._id !== commentId));
    
        } catch (error) {
            console.error('Error deleting comment:', error);
            // Handle the error appropriately in your UI
        }
    };

    function handleCardImgPress(userId){
        navigate('/User/' + userId, {
            state: {userId}
        })
    }

    function handleCommentHeight(message){
        if(message.length < 50){
            return '12.5rem'
        }else{
            return '18rem'
        }
    }

    function handleTopCardHeight(message, img){
        if(message.length > 100 && img){
            return "60%"
        }else if(message.length < 100){
            return "35%"
        }
    }
    const handleEditPost = (postId, message, imageUrl) => {
        setIsUpdatingPost(true)
        setPostContent(message)
        setPostImageUrl(imageUrl)
    }
    const handleUpdatePost = async () => {
        const formData = new FormData();
        formData.append('message', postContent);
        formData.append('postId', postId);
        formData.append('postImageUrl', selectedImage);
        try {
            const response = await editPost(formData); // Call the createPost function with formData
    
            if (response.ok) {
                // log('Post created successfully for ' + postId + '!');
                setIsUpdatingPost(false);
                // setSelectedImage(null); // Clear the selected image
                // getFollowerNotificationIds()
                navigate(0)
            } else {
                const error = await response.json();
                //alert('Error creating post (status): ' + error.message);
            }
        } catch (error) {
            console.error('Error creating post:', error);
           // alert('Error creating post: ' + error.message);
        }
    }

    const editPost = async (formData) => {
        for (let pair of formData.entries()) {
            // alert(`${pair[0]}: ${pair[1]}`);  // This will show an alert for each key-value pair
        }
        try {
            const addPost = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/updatePost`, {
                method: 'PATCH',
                body: formData,  // FormData now contains both text and image
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,  // Authorization token
                },
            });
    
            return addPost;  // Return the response for further handling
        } catch (error) {
            console.error('Error creating post:', error);
            throw error;  // Rethrow the error to be caught in the handlePostClick
        }
    }

    const isMobile = windowSize.width < 765

      function determinePage(){
        if(handleState == true){
            return(
                <div style={{height:'100vh', width:'100%', backgroundColor:'black'}}>
            <Navigation/>
            <div style={{marginTop:'1rem', height:'160vh', width:'100%', backgroundColor:'black', color:'#Ff9e28', display:'flex', justifyContent:'center'}}>
                <Card style={{width: isMobile ? '98%' : '50%', height:'98%', margin:'auto',borderColor:'#Ff9e28', backgroundColor:'black', borderBottomColor:'#Ff9e28'}}>
                <Card style={{backgroundColor:'black', borderBottomColor:'#Ff9e28', height: handleTopCardHeight(post.message,post.postImageUrl), width:'100%', justifyContent:'center'}}>
                <CardGroup style={{ display: 'flex', alignItems: 'center' }}>
                    <Card.Img style={{ width: '6rem', height: '6rem', marginLeft: '1rem', marginTop: '1rem', borderRadius:'50%' }} 
                    src={post?.profileImageUrl && post.profileImageUrl.trim() !== '' ? post.profileImageUrl : tasteMakersLogo}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleCardImgPress(post.userId)
                    }}
                    onError={(e) => {
                        e.target.onerror = null; // Prevents infinite callback loop
                        e.target.src = tasteMakersLogo; // Fallback image if loading fails
                    }}
                    alt="User Img" />
                    <CardGroup style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card.Title style={{ marginLeft: '1rem', marginTop: '1rem', marginBottom: '.25rem', color: 'white', fontSize:'1.5rem'}}>@{post.userId.replace(/['"]+/g, '')}</Card.Title>
                    <Card.Subtitle style={{ fontSize:'1.25rem',marginLeft:'1rem'// default color if none of the conditions are met
                        }}>
                        <span style={{color:'white'}}>Muxer </span><span style={{  color:
                            post.userTitle === 'Basic' ? 'black' :
                            post.userTitle === 'Seer' ? 'blue' :
                            post.userTitle === 'Certified' ? 'gold' :
                            post.userTitle === 'Pro' ? '#Ff9e28' :
                            post.userTitle === 'Prime'? 'red':
                            'black'
                            }}>{post.userTitle}</span>
                        </Card.Subtitle>
                    </CardGroup>

                    {post.userId === backendUser.userId ? (
                        <>
                    <Button 
                        style={{
                            width: '2rem', 
                            height: '2rem', 
                            display: 'flex',  
                            justifyContent: 'center',  // Center horizontally
                            alignItems: 'center',      // Center vertically
                            color: '#Ff9e28', 
                            backgroundColor: 'black', 
                            borderColor: '#Ff9e28', 
                            marginRight:'0rem',
                            marginTop: userId.length > 9 ? '3.5rem' : '2rem'
                        }}
                        onClick={() => handleEditPost(post._id, post.message, post.postImageUrl)} 
                    >
                <img 
                    src={editButton} 
                    style={{
                        width: '1.5rem', 
                        height: '2rem'
                    }}
                    alt="Edit Button"  // Add alt text for accessibility
                />
                    </Button>
                <Button 
                    style={{
                        height:'2rem',
                        width:'2rem',
                        display:'flex',  
                        color:'black', 
                        backgroundColor:'#Ff9e28', 
                        borderColor:'#Ff9e28', 
                        justifyContent: 'center',  // Center horizontally
                        alignItems: 'center',
                        marginTop: userId.length > 9 ? '3.5rem' : '2rem', 
                        marginLeft:'.5rem' 
                    }} 
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDeletePost(post._id);
                    }}
                >
                    <img
                    src={deleteButton}
                    style={{width:'2rem', height:'2rem'}}
                    />
                </Button>
                </>
                    ) : 
                     <Button
            style={{
                height:'2.5rem',
                width: '3rem',
                display: 'flex',
                alignItems: 'center',  // Center vertically
                justifyContent: 'center',  // Center horizontally
                color: '#Ff9e28',
                backgroundColor: '#Ff9e28',
                borderColor: '#Ff9e28',
                marginTop: userId.length > 9 ? '3.5rem' : '2rem',
                marginLeft: '1rem',
                borderRadius: '50px',
                padding: 0, // Optional: remove any default padding
            }}
            onClick={(e) => {
                e.stopPropagation();
                handleOpenReportModal()
            }}
        >
            <img
                src={reportButton}
                alt="Button Image"
                style={{ width: '2rem', height: '2rem' }}
            />
        </Button>
        }
                </CardGroup>

                <Card.Text style={{ fontSize:'1.20rem',marginLeft: '2rem', marginTop: '1rem', color: 'white'}}>{post.message}</Card.Text>
                                {post.postImageUrl ? (
                    <Card.Img 
                        style={{
                            maxWidth: '24rem',
                            maxHeight: '15rem',
                            marginBottom: '1rem',
                            display: 'block',  // Ensures the image is treated as a block-level element
                            marginLeft: 'auto',  // Centers the image horizontally
                            marginRight: 'auto'  // Centers the image horizontally
                        }} 
                        src={post.postImageUrl}
                    />
                ) : null}   
                <Card.Text style={{color:'white', display:'flex', justifyContent:'right'}}> 
                 
                <Button 
                onClick={(e) => {e.stopPropagation(); likeOrUnlikePost(post._id, backendUser.userId)}}
                style={{color:"#Ff9e28", backgroundColor:'black', borderColor:'#Ff9e28', marginRight:'1rem'}}>{post.likes.length} Likes  </Button> <Button style={{color:"#Ff9e28", backgroundColor:'black', borderColor:'#Ff9e28', marginRight:'1rem'}}> {post.comments.length} Comments </Button> </Card.Text>
                </Card>
                <Card style={{ height: '60%', width: '95%', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: 'black' }}>
                <CardGroup style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Card.Title style={{ color: 'white', marginBottom: '1rem', marginTop: '1rem', fontSize: '1.75rem', width: '90%', textAlign: 'center' }}>Comments</Card.Title>
                <Button style={{color:'#Ff9e28', backgroundColor:'black', borderColor:'#Ff9e28', marginBottom:'1rem', marginTop:'1rem'}}onClick={handleShowTextarea}>Add</Button>
            </CardGroup>
            {showTextarea && (
                <div style={{ position: 'relative' }}>
                    <textarea
                        rows="4" // You can adjust the number of rows as needed
                        cols="100" // You can adjust the number of columns as needed
                        placeholder="Type your comment here..."
                        style={{ width: '100%', backgroundColor: 'black', color: 'white',borderRadius:'10px' }} // Adjust styles as needed
                        value={commentText}
                        onChange={(e) => {
                            if (e.target.value.length <= 200) {
                                setCommentText(e.target.value);
                            }
                        }}
                        maxLength="200" // Restrict to 200 characters
                    ></textarea>
                    <p
                        style={{
                            position: 'absolute',
                            bottom: '5px',
                            right: '5px',
                            color: 'white',
                        }}
                    >
                        Remaining characters: {remainingCharacters}
                    </p>
                    <Button style={{marginBottom:'.5rem',color:'#Ff9e28', backgroundColor:'black', borderColor:'#Ff9e28'}} onClick={(e) => handlePostComment(post._id)}>Post</Button>
                </div>
            )}
                <Row className="flex" style={{ scrollbarColor: '#Ff9e28 black', scrollbarWidth: 'thin', WebkitOverflowScrolling: 'touch', height: '100%', width: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                {comments.slice().reverse().map(comment => {
                return (
                    <Card key={comment._id} style={{ width:'100%',height:handleCommentHeight(comment.text), color: "white", backgroundColor: 'black', borderColor: '#Ff9e28', marginBottom: '1rem', alignSelf: 'flex-start' }}>
                    <CardGroup style={{ justifyContent: 'space-between', alignItems: 'center', display:'flex'}}>
                        <Card.Img src={comment.profileImageUrl || tasteMakersLogo} style={{ marginTop: '.5rem', width: '5rem', height: '5rem', borderRadius:'50%' }} />
                        <Card.Title style={{ color: 'white', marginTop: '2rem', marginLeft: '1rem' }}>@{comment.userId.replace(/^"|"$/g, '')}</Card.Title>
                        <div style={{ flex: '1', display: 'flex', justifyContent: 'flex-end' }}>
                        {comment.userId === backendUser.userId && ( // Display delete button only if the comment is made by the logged-in user
                            <Button style={{color:'black', backgroundColor:'#Ff9e28', borderColor:'#Ff9e28'}} onClick={() => handleDeleteComment(comment._id, post._id, backendUser.userId, backendUser.profileImageUrl)}>Delete</Button>
                        )}
                        </div>
                    </CardGroup>
                    <Card style={{ backgroundColor: 'black', color: 'white' }}>
                        <Card.Body style={{ color: 'white' }}>{comment.text}</Card.Body>
                    </Card>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                        onClick={() => handleCommentLike(postId,comment._id, backendUser._id)}
                        style={{ width: "5rem", color:"#Ff9e28",
                             borderColor:"#Ff9e28", backgroundColor:"black"  }}>
                                { comment.likes.length} Likes </Button>
                    </div>
                    </Card>
                );
                })}

                </Row>
                </Card>


                </Card>
            </div>
            <Footer/>
        </div>
            )
        }else if(handleState == false){
            return(
                <div style={{height:'100%', width:'100%'}}>
            <Navigation/>
            <div style={{marginTop:'6rem', height:'100vh', width:'100%', backgroundColor:'black', color:'#Ff9e28'}}>
                404
            </div>
            <Footer/>
        </div>
            )
        }
      }
      
    return(
        <div style={{height:"100%"}}>
            {isUpdatingPost && (
            <div style={{
                position: 'fixed', 
                bottom: '10rem', 
                left: '1rem', 
                width: '90%', 
                backgroundColor: 'black',
                padding: '1rem',
                borderRadius: '10px',
                zIndex: 1000, 
            }}>
                {/* <Button style={{marginLeft:'4rem'}}>Cancel</Button> */}
            {selectedImage && (
                    <div style={{ marginTop: '1rem' }}>
                        <img 
                            src={URL.createObjectURL(selectedImage)} 
                            alt="" 
                            style={{ maxWidth: '100%', borderRadius: '10px' }}
                            onLoad={() => URL.revokeObjectURL(selectedImage)} 
                        />
                    </div>
            )}
            {!selectedImage ? <img src={postImageUrl} style={{width:'20rem',height:'12rem'}}/> : <></>
                }
                <textarea
                    rows="4" 
                    cols="100" 
                    placeholder="Type your Post here..."
                    style={{ 
                        width: '100%', 
                        backgroundColor: 'black', 
                        color: 'white',
                        borderRadius: '10px' 
                    }}
                    value={postContent}
                    onChange={(e) => {
                        if (e.target.value.length <= 200) {
                            setPostContent(e.target.value);
                        }
                    }}
                    maxLength="200" 
                ></textarea>
        
                {/* Add Image Input for Selecting File */}
                <label style={{ color: 'white', cursor: 'pointer' }}>
            <input 
                type="file" 
                accept="image/*" 
                style={{ display: 'none' }} 
                onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                        setSelectedImage(e.target.files[0]);  // Store the selected image in state
                    }
                }}
            />
            <Button 
                style={{ color: '#Ff9e28', borderColor: '#Ff9e28', backgroundColor: 'black' }}
                onClick={() => document.querySelector('input[type="file"]').click()}
            >
                Select Image
            </Button>
        </label>
        
        
                <Button style={{ color: '#Ff9e28', borderColor: '#Ff9e28', backgroundColor: 'black', marginLeft:'1rem'}} onClick={() => handleUpdatePost}>
                    Update
                </Button>
                <Button style={{ color: '#Ff9e28', borderColor: '#Ff9e28', backgroundColor: 'black', marginLeft:'1rem'}} onClick={() => handleCancelButton()}>
                    Cancel
                </Button>
        
                <p style={{ color: 'white', textAlign: 'right', margin: '0.5rem 0 0 0' }}>
                    Remaining characters: {remainingCharacters}
                </p>
        
                {/* Display selected image preview if any */}
        
            </div>
        )}
        {determinePage()}
        <ReportModal isOpen={showModal} onClose={handleCloseReportModal} postId={postId}>
    <h2>Report Post?</h2>
    <p>Please tell us what was wrong with this post.</p>
    <input
      value={inputValue}
      onChange={handleInputChange}  // Local input change handler
      placeholder="Please Enter Complaint"
      style={{ width: '100%', marginBottom: '1rem', padding: '0.5rem' }}
    />
    {/* Centering the Submit Button */}
    <div style={{ display: 'flex', justifyContent: 'center' }}>
    <Button 
    onClick={() => {
        submitPostReportDetail(backendUser._id,postId, userId, complaint);
    }}
    style={{ backgroundColor: '#Ff9e28', color: 'black', width: '6rem',
             justifyContent: 'center', alignItems: 'center', borderColor: 'black' }}>
    Submit
</Button>

    </div>
</ReportModal>
        </div>
    )
}