import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Container, InputGroup, FormControl, Button, Row, Card, CardGroup, Navbar, Image, Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
// import Navigation from './Navigation';

export default function Logout(){
    const navigate = useNavigate();
    sessionStorage.setItem('token','')
    sessionStorage.setItem("email",'');
    sessionStorage.setItem("userId",'');
    sessionStorage.setItem("spotifyToken",'')
    sessionStorage.clear()
    useEffect(() => {
        navigate("/")
      }, [])
    return(
        <div>
            <div>
                <h4>Logging Out</h4>
            </div>
        </div>
    );
}