import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Stack, Card, Modal} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import crown from './crown.png';
import goldOk from './gold_ok.png';
import grayOk from './gray_ok.png'

export default function Upgrade(){
    let [message, setMessage] = useState('');
    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState('');
    const [userPro, setUserPro] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
      },[]);
    
      useEffect(() => {
        function handleResize() {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
    
        window.addEventListener("resize", handleResize);
        handleResize(); // Set initial size on mount
    
        return () => window.removeEventListener("resize", handleResize);
      }, []);

      useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
    
        if (query.get('success')) {
          setSuccess(true);
          setSessionId(query.get('session_id'));
          updateUserProStatus()
        }
    
        if (query.get('canceled')) {
          setSuccess(false);
          setMessage(
            "Order canceled -- continue to shop around and checkout when you're ready."
          );
        }

        function updateUserProStatus(){
            // //console.log("TESTING SUCCESSFUL")
            // const changeUserProStatus = async () => {
                const response = fetch(process.env.REACT_APP_BACKEND_URL+'/api/users/updateProStatus',{
                    method: 'PATCH',
                    body: JSON.stringify({
                      "userId": sessionStorage.getItem("userId"),
                      "paidMember":true
                    }),
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  })
                  // .then(response => //console.log(response.json()))
                  // .then(//console.log("Success Scription in DB"))
                // .then(//console.log(compSubmissions[0].playlistsId))
            // }
        }
        function successOrMessage(){
            if (!success && message === '') {
              return //console.log("Failed to Subscribe")
            } else if (success && sessionId !== '') {
              return handleShow()
            } else {
              return <Message message={message} />;
            }
          }
          successOrMessage();
      }, [sessionId]);

      useEffect(() => {
    
        const response = fetch(process.env.REACT_APP_BACKEND_URL+'/api/users/'+ sessionStorage.getItem("userId"))
        .then(result => result.json())
        .then(data => setUserPro(data.paidMember))
        // .then(//console.log(userPro))
    
    }, [])
    
      const Message = ({ message }) => (
        <section>
          <p>{message}</p>
        </section>
      );
    function handleWindowSize(){
        if(windowSize.width < 765){
            return 'vertical'
        }else{
            return 'horizontal'
        }
    }
    function handleCardSize(){
        if(windowSize.width < 765){
            return '30rem'
        }else{
            return '30rem'
        }
    }
    function handleManageButton(){
    //  window.location.assign("https://billing.stripe.com/p/login/test_eVaaFXcPrayi6hWcMM")
    // Browser.open({ url:"https://docs.google.com/forms/d/e/1FAIpQLSeh8SZ2oHX19Es-thaSu5ijEPDyOYQGR4QMbgt5wvnE5e-KRQ/viewform?vc=0&c=0&w=1&flr=0"})
    }
    function handleProUser(){
      if(userPro == true){
        return(
            <div>
              <form action="/create-portal-session" method="POST">
                    <input
                      type="hidden"
                      id="session-id"
                      name="session_id"
                      value={sessionId}
                    />
                  <Button
                  onClick={() => handleManageButton()}
                  id="create-customer-portal-session" type="submit" 
                  style={{marginBottom:".25rem", color:"#ff914d",backgroundColor:"black", borderColor:"black", paddingLeft:"2rem",paddingRight:"2rem",marginTop:'.75rem'}}
                  >
                  Manage
                      
                  </Button>
                  </form>
            </div>
        )
      }else{
          return(
            <form action="/create-checkout-session" method="POST">
                                {/* Add a hidden field with the lookup_key of your Price */}
                                <input type="hidden" name="lookup_key" value="proMembership" />
                                <Button
                                id="checkout-and-portal-button" type="submit"
                                style={{marginBottom:".25rem", color:"#ff914d",backgroundColor:"black", borderColor:"black", paddingLeft:"2rem",paddingRight:"2rem",marginTop:'.75rem'}}
                                >
                                    Upgrade
                                </Button>
                                </form>
          )
      }
    }
    return(
        <div style={{backgroundColor:'black'}}>
            <Navigation/>
            <h1 style={{marginTop:"2rem",textAlign:"center", marginBottom:"2.5rem", color:"#Ff9e28"}}> Upgrade To PRO </h1>
            <div>
            <Container className='d-flex justify-content-center text-center'>
                <Stack direction={handleWindowSize()}>
                    <Card style={{width:handleCardSize(),height:'32rem', paddingTop:'.5rem', marginRight:'1rem',borderRadius: "1rem",backgroundColor:'black',borderColor:'#Ff9e28', marginLeft:'1rem'}}>
                            <Card.Title style={{fontSize:'2rem', color:'#Ff9e28'}}>Free</Card.Title>
                        <hr style={{marginBottom: '0', marginTop:'1rem'}}/>
                            <Card.Body className='text-center' style={{backgroundColor:'white'}}> 
                                <Card.Text style={{marginTop:'1rem', fontSize:'2rem'}}>
                                {/* This is here for design consistency */}
                                    $0 / month
                                </Card.Text>
                                <Card.Text>
                                    <img src={grayOk} alt='...'/>
                                    Social Media that doesn't sell your soul
                                </Card.Text>
                                <Card.Text>
                                    <img src={grayOk} alt='...'/>
                                    Get Paid for Going Viral
                                </Card.Text>
                                <Card.Text>
                                    <img src={grayOk} alt='...'/>
                                    Preview the Chamber
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    <Card style={{display:'center',width:'30rem',height:'32rem', paddingTop:'1rem',borderRadius: "1rem", backgroundColor:'black',borderColor:'#dbdbdb'}}>
                        <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <img style={{width:'2.3rem',height:'2.3rem', marginRight:'.5rem'}}src={crown}/>
                        <Card.Title style={{fontSize:'2rem', marginTop:'.35rem', marginBottom:'.25rem',color:"#FFD700", marginRight:'1.5rem',fontSize:'2rem',
                        textShadow: "0 0 0px #fff, 0 0 1px #fff, 0 0 5px #fff, 0 0 20px #FFD700, 0 0 30px #FFD700, 0 0 40px #FFD700, 0 0 55px #FFD700, 0 0 75px #FFD700"
                        }}>PRO</Card.Title>
                        </Container>
                     <hr style={{marginBottom: '0', marginTop:'1rem'}}/>
                     <Card.Body className='text-center' style={{backgroundColor:'white'}}>
                                <Card.Text style={{marginTop:'1rem', fontSize:'2rem'}}>
                                    $1.99 / month
                                </Card.Text>
                                <Card.Text>
                                    <img src={goldOk}/>
                                    Full Access To The Chamber
                                </Card.Text>
                                <Card.Text>
                                    <img src={goldOk}/>
                                    Removes Ads from the App
                                </Card.Text>
                                <Card.Text>
                                    <img src={goldOk}/>
                                    Ability to make Money in the Chamber
                                </Card.Text>
                                <Card.Text>
                                    <img src={goldOk}/>
                                    Gain Pro Badge for Profile
                                </Card.Text>
                                
                                {handleProUser()}
                            </Card.Body>
                     </Card>
                </Stack>
            </Container>
            </div>
            <div style={{marginTop:'4rem'}}>
                <Footer/>
            </div>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body> Successful, Welcome to Ergo</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
            {/* <BottomGoogleAd/> */}
        </div>
        
    )
}