import { useState, useEffect, useRef } from 'react';
import { Container, Row, Card, Button, Col, InputGroup, FormControl, CardGroup, Modal, ButtonGroup, CardDeck, Image, Carousel, CarouselItem} from 'react-bootstrap';
import Navigation from './Navigation';
import tasteMakersLogo from './Tastemakers Basic Logo.png'
import Footer from './Footer'
import moment from 'moment';
import reportButton from './report dark.png'
import { useNavigate, useLocation, Link} from 'react-router-dom';
import { App } from '@capacitor/app';
import ReportModal from './ReportModal'
import debounce from 'lodash.debounce';

import editButton from './editPen.png'
import deleteButton from './deleteButton.png'

export default function Feed(){
    const userImg = sessionStorage.getItem("imgURL");
    // const userId = sessionStorage.getItem("userId")
    const userEmail = sessionStorage.getItem("email")
    const [selectedImage, setSelectedImage] = useState(null);

    const timestamp = new Date().toLocaleString();
    const [posts,setPosts] = useState([]);
    const [topPosts,setTopPosts] = useState([]);
    const [topComments, setTopComments] = useState([]);
    const [getRealId, setGetRealId] = useState('');
    const [isLiked, setIsLiked] = useState(false);
    const [likes, setLikes] = useState(0);
    const [backendUser, setBackendUser] = useState([]);
    const [spotifyUser, setSpotifyUser] = useState([]);
    const [ownerId, setOwnerId] = useState('');
    const [userId, setUserId] = useState('');
    const navigate = useNavigate();
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [complaint, setComplaint] = useState('');  // State to store input value
    // const [followerIds,setFollowerIds] = useState([]);
    const [followerNotificationsIds, setFollowerNotificationsIds] = useState([]);
    const [isUpdatingPost,setIsUpdatingPost] = useState(false);
    const [userTitle, setUserTitle] = useState('');
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
      });

      const location = useLocation();
      const previousPathRef = useRef(null);
      const [lastScrollY, setLastScrollY] = useState(0); // State for tracking scroll position
      const [showModal, setShowModal] = useState(false);

    const handleOpenReportModal = () => {
        setShowModal(true);
    };
    const updateComplaint = debounce((value) => {
        setComplaint(value);
      }, 300); 

    const handleCloseReportModal = () => {
        setComplaint('')
        setInputValue('')
        setShowModal(false);
    };

    const submitPostReportDetail = async (ownerId, reportPostId, reportPostUserId, complaint) => {
        handleCloseReportModal();  // Close the modal
    
        try {
            const reportResponse = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/posts/reportPost', {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    postId: reportPostId,
                    userId: ownerId,
                    reportedUserId: reportPostUserId,
                    message: complaint
                })
            });
    
            // Check for network errors
        if (!reportResponse.ok) {
            // Try to parse the response as JSON
            let errorMessage = 'An error occurred';  // Default error message
        
            try {
                const errorResponse = await reportResponse.json();  // Parse response as JSON
                if (errorResponse.error) {
                    errorMessage = errorResponse.error;  // Extract the specific error message
                } else {
                    errorMessage = JSON.stringify(errorResponse);  // If no specific error, show the full response
                }
            } catch (err) {
                // If the response isn't valid JSON, fallback to plain text
                errorMessage = await reportResponse.text();  // Extract the plain text response
            }
        
            console.error('Error Response:', errorMessage);
            alert(errorMessage);
            return;
        }
    
            // Handle successful report
            alert("Post reported successfully.");
        } catch (error) {
            // Catch network errors or issues with fetch
            console.error('Fetch error:', error);
            alert('Failed to fetch: ' + error.message);
        }
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);  // Update the local input value immediately
        updateComplaint(value);  // Use debounced function to update the complaint
      };
      useEffect(() => {
        return () => {
          updateComplaint.cancel();  // Cleanup the debounce function on component unmount
        };
      }, []);
    
    // const submitPostReportDetail = async (ownerId,reportPostId,reportPostUserId,complaint) => {
    //     handleCloseReportModal();  // Invoke the function
    //     alert("ownerId: " + ownerId + " reportPost: " + reportPostId + " postUser: " + reportPostUserId + " complaint: " + complaint)
    //     alert(process.env.REACT_APP_BACKEND_URL)
    //     const reportResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/reportPost`, {
    //         method: 'PATCH',
    //         headers: {
    //           'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ postId: reportPostId,
    //             userId: ownerId,
    //             reportedUserId: reportPostUserId,
    //             message: complaint
    //          })
    //       });
    //       if(reportResponse.ok){
    //         alert("post reported")
    //       }else{
    //         alert(reportResponse.statusText)
    //       }
    // }

      useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY < lastScrollY) {
                // User scrolled up
                navigate(0); // Refresh the component by navigating to the same path
            }
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY, navigate]); // Include navigate in the dependency array
      useEffect(() => {
        const currentPath = window.location.pathname;

        const backButtonListener = App.addListener('backButton', () => {
            const previousPath = previousPathRef.current;

            if (currentPath === '/') {
                App.exitApp(); // Exit app if on the home page
            } else if (currentPath === '/Feed' && previousPath === '/') {
                // Do nothing if the current path is '/feed' and the previous path was '/'
            } else {
                navigate(-1); // Go to the previous page
            }

            // Update the previous path reference after navigation
            previousPathRef.current = currentPath;
        });

        // Set the previous path reference when the component mounts
        previousPathRef.current = location.pathname;

        // Cleanup the listener when the component unmounts
        return () => {
            backButtonListener.remove();
        };
    }, [navigate, location]);

      useEffect(() => {
        async function fetchData() {
          try {
            // Fetching register user data
            const registerResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/register/${userEmail}`);
            if (!registerResponse.ok) {
              throw new Error('Failed to fetch register user');
            }
            const registerData = await registerResponse.json();
            setSpotifyUser(registerData);
      
            // Fetching Spotify user data
            const spotifyResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + '\"' + registerData.userName + '\"', {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
      
            if (!spotifyResponse.ok) {
              throw new Error('Failed to fetch Spotify user');
            }
            const spotifyUserData = await spotifyResponse.json();
            setBackendUser(spotifyUserData);
            sessionStorage.setItem("userId",spotifyUserData.userId)
            setOwnerId(spotifyUserData._id)
            setUserId(spotifyUserData.userId)
            setProfileImageUrl(spotifyUserData.profileImageUrl)
            setUserTitle(spotifyUserData.userTitle)
            // setFollowerIds(spotifyUserData.followers)
      
            // Now that backendUser is set, fetch posts
            //console.log("spotiy user data: "+spotifyUserData)
            getData(spotifyUserData.userId);
      
          } catch (error) {
            console.error('Error fetching data:', error);
            navigate("/");
          }
        }
        async function getTopPostsBasedOnLikes() {
            try{
                const getPostsByLikes = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/top-posts/likes`, {
                    method: 'GET',
                    headers: {
                      'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                      'Content-Type': 'application/json',
                    },
                });
                
                if (getPostsByLikes.ok) {
                    const data = await getPostsByLikes.json(); // await the JSON parsing
                    // alert("Top Posts: " + JSON.stringify(data)); // Debugging alert
                    setTopPosts(data); // Set the data to your state
                } else {
                    alert('Failed to fetch top posts'); // Handle error
                }                
            }catch(e){

            }
        }
        async function getTopPostsBasedOnComments() {
            try{
                const getPostsByComments = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/top-posts/comments`, {
                    method: 'GET',
                    headers: {
                      'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                      'Content-Type': 'application/json',
                    },
                  });
                  if(getPostsByComments.ok){
                    const data = await getPostsByComments.json();
                    // alert("Top Comment Posts:"+JSON.stringify(data))
                    setTopComments(data)
                  }
            }catch(e){

            }
        }
      
        fetchData();
        getTopPostsBasedOnLikes();
        getTopPostsBasedOnComments();
      }, [
        // userEmail,
         isLiked
        //   posts
        ]); // Depends only on userEmail

      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      async function getFollowerNotificationIds(){
        alert("get ids")
        try{
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/user/${userId}/followers/notifications`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                  'Content-Type': 'application/json',
                },
              });

              if(!response.ok){
                alert("failed")
            throw new Error('Failed to fetch notificationIds for followers');
              }else{
                alert("success")
                const data = await response.json()
                // alert("Data:" + JSON.stringify(data.notificationIds))
                setFollowerNotificationsIds(data.notificationIds)
                // alert("FollowNotif"+followerNotificationsIds)
                    if(followerNotificationsIds.length > 0){
                        handleFollowNotification(data.notificationIds,userId)
                    }else{
                        navigate(0)
                    }
              }
        }catch(e){
            console.error('Error fetching notificationIds:', e);
        }
      }

      async function getData(userId) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/getAllUserPosts/${userId}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
              'Content-Type': 'application/json',
            },
          });
      
          if (!response.ok) {
            throw new Error('Failed to fetch posts');
          }
          const data = await response.json();
          //console.log(data)
          setPosts(data);
        } catch (error) {
          console.error('Error fetching posts:', error);
        }
      }

      useEffect(() => {
        //console.log('Effect triggered:', { posts, likes, isLiked });
        // Rest of your code...
    }, [posts, likes, isLiked, backendUser]);

    //   useEffect(() => {

    //   }, [posts, likes, isLiked]);

      useEffect(() => {

        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        };
      
        window.addEventListener("resize", handleResize);
        handleResize(); // Set initial size on mount
      
        return () => window.removeEventListener("resize", handleResize);

        likeOrUnlikePost();
        
      }, []);

      useEffect(() => {

      }, [posts])

    const likeOrUnlikePost = async (postId, userId) => {
        try {
            // Fetch user details to get realId
            const userRealIdResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + userId,{
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
            });
            const userData = await userRealIdResponse.json();

            if (!userData || !userData._id) {
                console.error('User data or user ID not found');
                return;
            }

            const realId = backendUser._id;
            //console.log('Real ID:', realId);

            // Fetch post details to check if realId is in the likePost array
            const postResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/getPost/' + postId,{
                method:'GET',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
            });
            const postData = await postResponse.json();
            //console.log(postData)
            const likePostArray = postData.likes || [];
            const isLiked = likePostArray.some(like => like.userId === realId);

            if (isLiked) {
                // If already liked, unlike the post
                const unlikeResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/unLikePost/' + postId + '/user/' + realId, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                        'Content-Type': 'application/json',
                      },
                });
                const unlikeResult = await unlikeResponse.json();
                //console.log(unlikeResult);
            } else {
                // If not liked, like the post
                const likeResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/likePost/' + postId + '/user/' + realId, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                        'Content-Type': 'application/json',
                      },
                });
                const likeResult = await likeResponse.json();
                //console.log(likeResult);
            }

            // Update the state to trigger a re-render
            setIsLiked((prevIsLiked) => !prevIsLiked);

            setLikes(likePostArray.length);
        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    };
    
    function handleCardPress(postId,userId){
        navigate('/User/' + userId + '/Post/' + postId, {
            state: {userId, postId}
        })
    }

    function handleCardImgPress(userId){
        navigate('/User/' + userId, {state: {userId}})
    }

    const [isCreatingPost, setIsCreatingPost] = useState(false);
    const [postContent, setPostContent] = useState('');

    // Event handler for the Create button
    const handleCreateClick = () => {
      setIsCreatingPost(!isCreatingPost); // Toggle the visibility of the textarea
      if(isCreatingPost == false){
        setPostContent(''); 
        setSelectedImage(null);  
      }
  };
  const handleEditClick = (message) => {
    setIsCreatingPost(!isCreatingPost); // Toggle the visibility of the textarea
    setPostContent(message)
    if(isCreatingPost == false){
      setPostContent(''); 
      setSelectedImage(null);  
    }
};

    // Event handler for the Post button
    const handlePostClick = async () => {
        if (!postContent && !selectedImage) {
            alert('Please enter a message or select an image before posting');
            return;
        }
    
        // Create a FormData object to handle both text and image data
        const formData = new FormData();
        formData.append('message', postContent);
        formData.append('ownerId', ownerId);
        formData.append('userId', userId);
        formData.append('profileImageUrl', profileImageUrl);
        formData.append('userTitle', userTitle);
    
        // If an image is selected, append it to the FormData
        if (selectedImage) {
            formData.append('image', selectedImage);  // Attach the selected image
        }
    
        try {
            const response = await createPost(formData); // Call the createPost function with formData
    
            if (response.ok) {
                alert('Post created successfully!');
                setIsCreatingPost(false);
                setPostContent(''); // Clear the text area after posting
                setSelectedImage(null); // Clear the selected image
                // createPostNotification()
                getFollowerNotificationIds()
                // navigate(0)
            } else {
                const error = await response.json();
                alert('Error creating post: ' + error.message);
            }
        } catch (error) {
            console.error('Error creating post:', error);
            alert('Error creating post: ' + error.message);
        }
    };
    

    const createPost = async (formData) => {
        try {
            const addPost = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/createPost`, {
                method: 'POST',
                body: formData,  // FormData now contains both text and image
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,  // Authorization token
                },
            });
    
            return addPost;  // Return the response for further handling
        } catch (error) {
            console.error('Error creating post:', error);
            throw error;  // Rethrow the error to be caught in the handlePostClick
        }
    };
    const handleUpdatePostClick = async (postId) => {
        if (!postContent && !selectedImage) {
            alert('Please enter a message or select an image before updating');
            return;
        }
    
        setIsCreatingPost(true);  // Start the updating process (reuse isCreatingPost)
    
        // Create a FormData object to handle both text and image data
        const formData = new FormData();
        formData.append('message', postContent);
        formData.append('postId', postId);
    
        // If an image is selected, append it to the FormData
        if (selectedImage) {
            formData.append('image', selectedImage);  // Attach the selected image
        }
    
        try {
            const response = await updatePost(postId, formData); // Call the updatePost function with formData
    
            if (response.ok) {
                alert('Post updated successfully!');
                setIsCreatingPost(false);  // Update complete, reuse isCreatingPost
                setPostContent('');        // Clear the text area after updating
                setSelectedImage(null);    // Clear the selected image
                // Additional actions like reloading the updated post list
            } else {
                const error = await response.json();
                alert('Error updating post: ' + error.message);
                setIsCreatingPost(false);  // Update failed, reset isCreatingPost
            }
        } catch (error) {
            console.error('Error updating post:', error);
            alert('Error updating post: ' + error.message);
            setIsCreatingPost(false);  // Reset isCreatingPost in case of error
        }
    };
    
    const updatePost = async (formData) => {
        try {
            const updatedPost = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/updatePost`, {
                method: 'PATCH',  // Use PUT or PATCH for updates
                body: formData,  // FormData contains both text and image
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,  // Authorization token
                },
            });
    
            return updatedPost;  // Return the response for further handling
        } catch (error) {
            console.error('Error updating post:', error);
            throw error;  // Rethrow the error to be caught in handleUpdatePostClick
        }
    };
    

    const createPostNotification = async () => {
        try {
            const sendNewPostNotification = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/`)
        }catch(error){

        }
    }
    
    async function handleFollowNotification(notificationIds, followerName) {
        // alert("onesignal code reached");
        // alert("NotificationIds: " + notificationIds);  // Debugging
        alert("send followers post notif block")
        if (!notificationIds) {
            alert("No device ID found for followers.");
            return;
        }
    
        try {
                        // Create the request body and make sure notificationIds is NOT stringified separately
            const requestBody = JSON.stringify({ notificationIds, followerName });

            // Alert the request body for debugging purposes
            alert("Request Body: " + requestBody); 

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/send-post-notification`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                },
                body: requestBody // Send the correct JSON string
            });

            // alert(response)
            if (response.ok) {
                const result = await response.json();
                alert('Notification sent successfully: ' + JSON.stringify(result));
                navigate(0);
            } else {
                const errorData = await response.json();
                alert('Failed to send notification: ' + errorData.error + ' - ' + (errorData.details || ''));
                navigate(0);
            }
        } catch (error) {
            alert('Error sending notification: ' + error.message);
            navigate(0);
        }
    }
    
    const [reportPostId,setReportPostId] = useState('');
    const [reportPostUserId,setReportPostUserId] = useState('');
    const handleReportPost = async (postId,postUserId) =>{
        setReportPostId(postId)
        setReportPostUserId(postUserId)
        handleOpenReportModal()
    }

    const handleDeletePost = async (postId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/deletePost`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    postId: postId,
                })
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }else{
               navigate(0)
            }
    
            // If the delete operation was successful
            // const deleteResult = await response.json();
            // //console.log(deleteResult);
    
            // Update your state to remove the deleted comment
            // For example, you might filter out the deleted comment from your comments state
            // setComments(prevComments => prevComments.filter(comment => comment._id !== commentId));
    
        } catch (error) {
            console.error('Error deleting comment:', error);
            // Handle the error appropriately in your UI
        }
    };
    function handleHCardHeight(message, image){
        var returnVal = null;
        if(message.length > 100 && !image){
          returnVal = "20.5rem"
        }else if(message.length < 25 && image){
            returnVal = "32rem"
        }else if(message.length > 10 && message.length < 100 && image){
            returnVal = "33rem"
        }else if(message.length > 100 && image){
            returnVal = "39rem"
        }
        else{
          returnVal = "17rem"
        }
        return returnVal;
      }

      function handleDate(firstDate, updatedDate) {
        // const dateToFormat = updatedDate === firstDate ? firstDate : updatedDate;
        //update does if there is a like, don't want that, need to look into new ways to implement
        const dateMoment = moment(firstDate);
        return dateMoment.format('MMM DD, YYYY hh:mm:ss a');
    }
    

    const remainingCharacters = 200 - postContent.length;

    function handleMobile(){
    if(windowSize.width < 765){
        //This is for MOBILE
        return(
                <div style={{backgroundColor:'black', height: '80%', width: '100%', margin: 0,  alignContent:'center', alignIems:'center', justifyContent:'center', justifyItems:"center"}}>
                    
                    <div style={{height:'90vh',width: '100%',alignContent:'center', alignIems:'center', justifyContent:'center', justifyItems:"center", display:'flex',flexDirection: 'column'}}>
                    <Carousel defaultActiveIndex={2} interval={null}>
                    <CarouselItem  interval={null}>
                        <Card style={{backgroundColor:'hsla(0, 0%, 0%, 0.5)'}}>
                            <Card.Body>
                            <div style={{width:'90vw'}}>
                                <div style={{height:'85vh', width:'90vw'}}>
                                    <h2 style={{color:'white', textAlign:'center'}}>Trending (Likes)</h2>
                                    <Col style={{alignContent:'center', alignIems:'center', justifyContent:'center', justifyItems:"center", display:'flex', width:'100%'}}>
                            <Row className="flex" style={{ scrollbarColor: '#Ff9e28 black',justifyContent:'center', alignItems: 'center', justifyItems:"center", scrollbarWidth:'thin', WebkitOverflowScrolling: 'touch', height: '80vh', width: '100%', overflowX: 'hidden', overflowY: 'auto'}}>
                                    { posts.length > 0 ? 
topPosts.slice().map(post => (
    <Card key={post._id} style={{ marginBottom:'.3rem',width:'100%',height: handleHCardHeight(post.message, post.postImageUrl), backgroundColor: 'black', borderColor: '#925000'}}  onClick={() => handleCardPress(post._id, post.ownerId)}>
        <CardGroup style={{width:"100%", display:'flex'}}>
            <Card.Img style={{borderRadius:'50%', width: '6rem', height: '6rem', marginTop: '1rem' }}
            src={post?.profileImageUrl && post.profileImageUrl.trim() !== '' ? post.profileImageUrl : tasteMakersLogo}
            alt="Profile Image"
            onError={(e) => {
                e.target.onerror = null; // Prevents infinite callback loop
                e.target.src = tasteMakersLogo; // Fallback image if loading fails
            }}
                onClick={(e) => {
                    e.stopPropagation();
                    handleCardImgPress(post.userId);
                }}
            />
            <CardGroup style={{ display: 'flex', flexDirection: 'column', width:'70%' }}>
                <Card.Title style={{ marginLeft: '1rem', marginTop: '2rem', marginBottom: '.25rem', color: 'white' }}>@{post.userId.replace(/^"|"$/g, '')}</Card.Title>
                <Card.Subtitle style={{ marginLeft:'1rem' }}>
                    <span style={{color:'white'}}>Muxer </span>
                    <span style={{ color: post.userTitle === 'Basic' ? 'black' :
                                            post.userTitle === 'Seer' ? 'blue' :
                                            post.userTitle === 'Certified' ? 'gold' :
                                            post.userTitle === 'Pro' ? '#Ff9e28' :
                                            post.userTitle === 'Prime'? 'red':
                                            'black'
                    }}>{post.userTitle}</span>
                </Card.Subtitle>
            </CardGroup>
            {post.userId === userId ? (
                <>
                <Button 
                    style={{
                        minHeight:'1rem', 
                        maxHeight:'2rem', 
                        display:'flex',  
                        color:'#Ff9e28', 
                        backgroundColor:'black', 
                        borderColor:'black', 
                        borderColor:'#Ff9e28',
                        justifyContent:"flex-end", 
                        marginTop:"2rem", 
                        marginLeft:'1rem'
                    }} 
                    onClick={(e) => {
                        e.stopPropagation();
                        // handleDeletePost(post._id);
                    }}
                >
                    Edit
                </Button>
                <Button 
                    style={{
                        minHeight:'1rem', 
                        maxHeight:'2rem', 
                        display:'flex',  
                        color:'black', 
                        backgroundColor:'#Ff9e28', 
                        borderColor:'#Ff9e28', 
                        justifyContent:"flex-end", 
                        marginTop:"2rem", 
                        marginLeft:'.5rem' 
                    }} 
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDeletePost(post._id);
                    }}
                >
                    X
                </Button>
                </>
            ) : <Button
            style={{
                height:'2.5rem',
                width: '3rem',
                display: 'flex',
                alignItems: 'center',  // Center vertically
                justifyContent: 'center',  // Center horizontally
                color: '#Ff9e28',
                backgroundColor: '#Ff9e28',
                borderColor: '#Ff9e28',
                marginTop: '2rem',
                marginLeft: '1rem',
                borderRadius: '50px',
                padding: 0, // Optional: remove any default padding
            }}
            onClick={(e) => {
                e.stopPropagation();
                handleReportPost(post._id, post.ownerId);
            }}
        >
            <img
                src={reportButton}
                alt="Button Image"
                style={{ width: '2rem', height: '2rem' }}
            />
        </Button>
        }
        </CardGroup>
        <Card.Text style={{ marginLeft: '2rem', marginTop: '1rem', color: 'white' }}>{post.message}</Card.Text>
        {post.postImageUrl && (
    <Card.Img 
        style={{
            width: '24rem',
            height: '15rem',
            marginBottom: '1rem',
            display: 'block',  // Ensures the image is treated as a block-level element
            marginLeft: 'auto',  // Centers the image horizontally
            marginRight: 'auto'  // Centers the image horizontally
        }} 
        src={post.postImageUrl}
    />
)}
        <Card.Text style={{color:'white', justifyContent:"right", display:'flex'}}>
            <Button onClick={(e) => {e.stopPropagation(); likeOrUnlikePost(post._id, backendUser.userId); }} style={{marginRight:'1rem', color:"#Ff9e28", backgroundColor:'black', borderColor:'#Ff9e28'}}>
                {post.likes.length} Likes
            </Button>
            <Button style={{ color: '#Ff9e28', backgroundColor: 'black', borderColor: '#Ff9e28' }}>
                {post.comments.length} Comments
            </Button>
        </Card.Text>
        <Card.Text style={{color: '#Ff9e28'}}>
            {post.updatedAt === post.createdAt ? 'Date: ' : 'Edit: '}
            <span style={{color: 'white'}}>
                {handleDate(post.createdAt.toString(), post.updatedAt.toString())}
            </span>
        </Card.Text>


    </Card>
)) :     <Card style={{ width: '90vw', height: '50vh', backgroundColor: 'rgba(64, 64, 64, 0.5)', borderColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
<h1 style={{ color: '#Ff9e28', textAlign: 'center' }}>Trending Loading!</h1>
</Card>}
</Row></Col>

                                </div>
                            </div>
                            </Card.Body>
                        </Card>
                    </CarouselItem>
                    <CarouselItem  interval={null}>
                        <Card style={{backgroundColor:'hsla(0, 0%, 0%, 0.5)'}}>
                            <Card.Body>
                            <div>
                                <div style={{height:'85vh'}}>
                                    <h2 style={{color:'white', textAlign:'center'}}>Trending (Comments)</h2>
                                    <Col style={{alignContent:'center', alignIems:'center', justifyContent:'center', justifyItems:"center", display:'flex', width:'100%'}}>
                            <Row className="flex" style={{ scrollbarColor: '#Ff9e28 black',justifyContent:'center', alignItems: 'center', justifyItems:"center", scrollbarWidth:'thin', WebkitOverflowScrolling: 'touch', height: '80vh', width: '100%', overflowX: 'hidden', overflowY: 'auto'}}>
                                    { posts.length > 0 ? 
topComments.slice().map(post => (
    <Card key={post._id} style={{ marginBottom:'.3rem',width:'100%',height: handleHCardHeight(post.message, post.postImageUrl), backgroundColor: 'black', borderColor: '#925000'}}  onClick={() => handleCardPress(post._id, post.ownerId)}>
        <CardGroup style={{width:"100%", display:'flex'}}>
            <Card.Img style={{borderRadius:'50%', width: '6rem', height: '6rem', marginTop: '1rem' }}
            src={post?.profileImageUrl && post.profileImageUrl.trim() !== '' ? post.profileImageUrl : tasteMakersLogo}
            alt="Profile Image"
            onError={(e) => {
                e.target.onerror = null; // Prevents infinite callback loop
                e.target.src = tasteMakersLogo; // Fallback image if loading fails
            }}
                onClick={(e) => {
                    e.stopPropagation();
                    handleCardImgPress(post.userId);
                }}
            />
            <CardGroup style={{ display: 'flex', flexDirection: 'column', width:'70%' }}>
                <Card.Title style={{ marginLeft: '1rem', marginTop: '2rem', marginBottom: '.25rem', color: 'white' }}>@{post.userId.replace(/^"|"$/g, '')}</Card.Title>
                <Card.Subtitle style={{ marginLeft:'1rem' }}>
                    <span style={{color:'white'}}>Muxer </span>
                    <span style={{ color: post.userTitle === 'Basic' ? 'black' :
                                            post.userTitle === 'Seer' ? 'blue' :
                                            post.userTitle === 'Certified' ? 'gold' :
                                            post.userTitle === 'Pro' ? '#Ff9e28' :
                                            post.userTitle === 'Prime'? 'red':
                                            'black'
                    }}>{post.userTitle}</span>
                </Card.Subtitle>
            </CardGroup>
            {post.userId === userId ? (
                <>
                <Button 
                    style={{
                        minHeight:'1rem', 
                        maxHeight:'2rem', 
                        display:'flex',  
                        color:'#Ff9e28', 
                        backgroundColor:'black', 
                        borderColor:'black', 
                        borderColor:'#Ff9e28',
                        justifyContent:"flex-end", 
                        marginTop:"2rem", 
                        marginLeft:'1rem'
                    }} 
                    onClick={(e) => {
                        e.stopPropagation();
                        // handleDeletePost(post._id);
                    }}
                >
                    Edit
                </Button>
                <Button 
                    style={{
                        minHeight:'1rem', 
                        maxHeight:'2rem', 
                        display:'flex',  
                        color:'black', 
                        backgroundColor:'#Ff9e28', 
                        borderColor:'#Ff9e28', 
                        justifyContent:"flex-end", 
                        marginTop:"2rem", 
                        marginLeft:'.5rem' 
                    }} 
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDeletePost(post._id);
                    }}
                >
                    X
                </Button>
                </>
            ) : <Button
            style={{
                height:'2.5rem',
                width: '3rem',
                display: 'flex',
                alignItems: 'center',  // Center vertically
                justifyContent: 'center',  // Center horizontally
                color: '#Ff9e28',
                backgroundColor: '#Ff9e28',
                borderColor: '#Ff9e28',
                marginTop: '2rem',
                marginLeft: '1rem',
                borderRadius: '50px',
                padding: 0, // Optional: remove any default padding
            }}
            onClick={(e) => {
                e.stopPropagation();
                handleReportPost(post._id, post.ownerId);
            }}
        >
            <img
                src={reportButton}
                alt="Button Image"
                style={{ width: '2rem', height: '2rem' }}
            />
        </Button>
        }
        </CardGroup>
        <Card.Text style={{ marginLeft: '2rem', marginTop: '1rem', color: 'white' }}>{post.message}</Card.Text>
        {post.postImageUrl && (
    <Card.Img 
        style={{
            width: '24rem',
            height: '15rem',
            marginBottom: '1rem',
            display: 'block',  // Ensures the image is treated as a block-level element
            marginLeft: 'auto',  // Centers the image horizontally
            marginRight: 'auto'  // Centers the image horizontally
        }} 
        src={post.postImageUrl}
    />
)}
        <Card.Text style={{color:'white', justifyContent:"right", display:'flex'}}>
            <Button onClick={(e) => {e.stopPropagation(); likeOrUnlikePost(post._id, backendUser.userId); }} style={{marginRight:'1rem', color:"#Ff9e28", backgroundColor:'black', borderColor:'#Ff9e28'}}>
                {post.likes.length} Likes
            </Button>
            <Button style={{ color: '#Ff9e28', backgroundColor: 'black', borderColor: '#Ff9e28' }}>
                {post.comments.length} Comments
            </Button>
        </Card.Text>
        <Card.Text style={{color: '#Ff9e28'}}>
            {post.updatedAt === post.createdAt ? 'Date: ' : 'Edit: '}
            <span style={{color: 'white'}}>
                {handleDate(post.createdAt.toString(), post.updatedAt.toString())}
            </span>
        </Card.Text>


    </Card>
)) :     <Card style={{ width: '90vw', height: '50vh', backgroundColor: 'rgba(64, 64, 64, 0.5)', borderColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
<h1 style={{ color: '#Ff9e28', textAlign: 'center' }}>Trending Loading!</h1>
</Card>}
</Row></Col>
                                </div>
                                
                            </div>
                            </Card.Body>
                        </Card>
                    </CarouselItem>

                    <CarouselItem  interval={null} style={{justifyContent:'center', alignContent:'center', alignItems: 'center', justifyItems:"center" }}>
                    <style>
        {`
          .carousel-control-prev,
          .carousel-control-next {
            display: none;
          }
        `}
      </style>
                    <Card style={{backgroundColor:'hsla(0, 0%, 0%, 0.5)',justifyContent:'center', alignContent:'center', alignItems: 'center', justifyItems:"center", width:"100%" }}>
                        <Card.Body style={{height:'90vh', width:'110vw'}}>
                      {/* <h2 style={{textAlign:'center', color:'white'}}>Feed</h2> */}
                      {/* <hr style={{ height:'.5rem', marginBottom:'2rem', marginTop:'0rem'}}/> */}
                        <div style={{display:'flex', width:'100%'}}>
                            <div  style={{alignContent:'center', alignIems:'center', justifyContent:'center', justifyItems:"center", display:'flex', width:'100%'}}>
                            <Col style={{alignContent:'center', alignIems:'center', justifyContent:'center', justifyItems:"center", display:'flex', width:'80%'}}>
                            <Row className="flex" style={{ scrollbarColor: '#Ff9e28 black',justifyContent:'center', alignItems: 'center', justifyItems:"center", scrollbarWidth:'thin', WebkitOverflowScrolling: 'touch', height: '80vh', width: '90%', overflowX: 'hidden', overflowY: 'auto'}}>
                            <Button 
    onClick={handleCreateClick} 
    style={{ 
        borderRadius: '50%', 
        width: '4rem',      
        height: '4rem',     
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'black',
        color: '#Ff9e28',
        borderColor: "#Ff9e28",
        borderWidth: '0.21875rem', 
        fontSize: '2.5rem',
        position: 'fixed',
        bottom: '3rem', 
        right: '12rem',  
        zIndex: 1000 
    }}
>
{isCreatingPost ? 'x' : '+'}
</Button>

{isCreatingPost && (
    <div style={{
        position: 'fixed', 
        bottom: '10rem', 
        left: '1rem', 
        width: '90%', 
        backgroundColor: 'black',
        padding: '1rem',
        borderRadius: '10px',
        zIndex: 1000, 
    }}>
    {selectedImage && (
            <div style={{ marginTop: '1rem' }}>
                <img 
                    src={URL.createObjectURL(selectedImage)} 
                    alt="" 
                    style={{ maxWidth: '100%', borderRadius: '10px' }}
                    onLoad={() => URL.revokeObjectURL(selectedImage)} 
                />
            </div>
    )}
        <textarea
            rows="4" 
            cols="100" 
            placeholder="Type your Post here..."
            style={{ 
                width: '100%', 
                backgroundColor: 'black', 
                color: 'white',
                borderRadius: '10px' 
            }}
            value={postContent}
            onChange={(e) => {
                if (e.target.value.length <= 200) {
                    setPostContent(e.target.value);
                }
            }}
            maxLength="200" 
        ></textarea>

        {/* Add Image Input for Selecting File */}
        <label style={{ color: 'white', cursor: 'pointer' }}>
    <input 
        type="file" 
        accept="image/*" 
        style={{ display: 'none' }} 
        onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
                setSelectedImage(e.target.files[0]);  // Store the selected image in state
            }
        }}
    />
    <Button 
        style={{ color: '#Ff9e28', borderColor: '#Ff9e28', backgroundColor: 'black' }}
        onClick={() => document.querySelector('input[type="file"]').click()}
    >
        Select Image
    </Button>
</label>


        <Button style={{ color: '#Ff9e28', borderColor: '#Ff9e28', backgroundColor: 'black', marginTop: '1rem' }} onClick={handlePostClick}>
            Post
        </Button>

        <p style={{ color: 'white', textAlign: 'right', margin: '0.5rem 0 0 0' }}>
            Remaining characters: {remainingCharacters}
        </p>

        {/* Display selected image preview if any */}

    </div>
)}

{ posts.length > 0 ? 
posts.slice().reverse().filter(post => !post.reports.some(report => report.userId === ownerId)).map(post => (
    <Card key={post._id} style={{ marginBottom:'.3rem',width:'100%',height: handleHCardHeight(post.message, post.postImageUrl), backgroundColor: 'black', borderColor: '#925000'}}  onClick={() => handleCardPress(post._id, post.ownerId)}>
        <CardGroup style={{width:"100%", display:'flex'}}>
        <Card.Img style={{borderRadius:'50%', width: '6rem', height: '6rem', marginTop: '1rem' }}
            src={post?.profileImageUrl && post.profileImageUrl.trim() !== '' ? post.profileImageUrl : tasteMakersLogo}
            alt="Profile Image"
            onError={(e) => {
                e.target.onerror = null; // Prevents infinite callback loop
                e.target.src = tasteMakersLogo; // Fallback image if loading fails
            }}
                onClick={(e) => {
                    e.stopPropagation();
                    handleCardImgPress(post.userId);
                }}
            />
            <CardGroup style={{ display: 'flex', flexDirection: 'column', width:'70%' }}>
                <Card.Title style={{ marginLeft: '1rem', marginTop: '2rem', marginBottom: '.25rem', color: 'white' }}>@{post.userId.replace(/^"|"$/g, '')}</Card.Title>
                <Card.Subtitle style={{ marginLeft:'1rem' }}>
                    <span style={{color:'white'}}>Muxer </span>
                    <span style={{ color: post.userTitle === 'Basic' ? 'black' :
                                            post.userTitle === 'Seer' ? 'blue' :
                                            post.userTitle === 'Certified' ? 'gold' :
                                            post.userTitle === 'Pro' ? '#Ff9e28' :
                                            post.userTitle === 'Prime'? 'red':
                                            'black'
                    }}>{post.userTitle}</span>
                </Card.Subtitle>
            </CardGroup>
            {post.userId === userId ? (
                <>
                <Button 
                    style={{
                        height:'2rem',
                        width:'2rem',
                        display:'flex',  
                        color:'#Ff9e28', 
                        backgroundColor:'black', 
                        borderColor:'black', 
                        borderColor:'#Ff9e28',
                        justifyContent: 'center',  // Center horizontally
                        alignItems: 'center',
                        marginTop: userId.length > 9 ? '3.5rem' : '2rem',
                        marginLeft:'1rem'
                    }} 
                >
                                    <img 
                    src={editButton} 
                    style={{
                        width: '1.15rem', 
                        height: '2rem'
                    }}
                    alt="Edit Button"  // Add alt text for accessibility
                />
                </Button>
                <Button 
                    style={{
                        width:'2rem',
                        height:'2rem',
                        display:'flex',  
                        color:'black', 
                        backgroundColor:'#Ff9e28', 
                        borderColor:'#Ff9e28', 
                        justifyContent: 'center',  // Center horizontally
                        alignItems: 'center',
                        marginTop: userId.length > 9 ? '3.5rem' : '2rem', 
                        marginLeft:'.5rem' 
                    }} 
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDeletePost(post._id);
                    }}
                >
                   <img
                   src={deleteButton}
                   style={{width:'2rem',height:'2rem'}}
                   />
                </Button>
                </>
            ) : <>
            <Button
                style={{
                    height: '2.5rem',
                    width: '3rem',
                    display: 'flex',
                    alignItems: 'center',  // Center vertically
                    justifyContent: 'center',  // Center horizontally
                    color: '#Ff9e28',
                    backgroundColor: '#Ff9e28',
                    borderColor: '#Ff9e28',
                    marginTop: userId.length > 9 ? '3.5rem' : '2rem',
                    marginLeft: '1rem',
                    borderRadius: '50px',
                    padding: 0, // Optional: remove any default padding
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    handleReportPost(post._id, post.ownerId);  // Call the handleReportPost function with the post ID
                }}
            >
                <img
                    src={reportButton}
                    alt="Button Image"
                    style={{ width: '2rem', height: '2rem' }}
                />
            </Button>
        </>
        
        }
        </CardGroup>
        <Card.Text style={{ marginLeft: '2rem', marginTop: '1rem', color: 'white' }}>{post.message}</Card.Text>
        {post.postImageUrl && (
    <Card.Img 
        style={{
            width: '22rem',
            height: '15rem',
            marginBottom: '1rem',
            display: 'block',  // Ensures the image is treated as a block-level element
            marginLeft: 'auto',  // Centers the image horizontally
            marginRight: 'auto'  // Centers the image horizontally
        }} 
        src={post.postImageUrl}
    />
)}

        <Card.Text style={{color:'white', justifyContent:"right", display:'flex'}}>
            <Button onClick={(e) => {e.stopPropagation(); likeOrUnlikePost(post._id, backendUser.userId); }} style={{marginRight:'1rem', color:"#Ff9e28", backgroundColor:'black', borderColor:'#Ff9e28'}}>
                {post.likes.length} Likes
            </Button>
            <Button style={{ color: '#Ff9e28', backgroundColor: 'black', borderColor: '#Ff9e28' }}>
                {post.comments.length} Comments
            </Button>
        </Card.Text>
        
        <Card.Text style={{color: '#Ff9e28'}}>
            {/* {post.updatedAt === post.createdAt ?  */}
            Posted: 
            {/* //  : 'Edit: '} */}
            <span style={{color: 'white', marginLeft:'.2rem'}}>
                {handleDate(post.createdAt.toString(), post.updatedAt.toString())}
                {/* {post.updatedAt === post.createdAt ? '' : ' (Edited) '} */}
            </span>
                        
        </Card.Text>
        


    </Card>
))
 :     <Card style={{ width: '90vw', height: '50vh', backgroundColor: 'rgba(64, 64, 64, 0.5)', borderColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
<h1 style={{ color: '#Ff9e28', textAlign: 'center' }}>Post Now!</h1>
</Card>}


                            </Row>
                            </Col>
                            </div>
                        </div>
                        </Card.Body>
                        </Card>
                        </CarouselItem>
                        <CarouselItem  interval={null}>
                        <Card style={{backgroundColor:'hsla(0, 0%, 0%, 0.5)'}}>
                            <Card.Body>
                            <div>
                                <div style={{height:'85vh'}}>
                                    <h2 style={{color:'white', textAlign:'center'}}>The Chamber</h2>
                                    <Card style={{ width: '90vw', height: '70vh', backgroundColor: 'rgba(64, 64, 64, 0.5)', borderColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <h1 style={{ color: '#Ff9e28', textAlign: 'center' }}>Coming soon...</h1>
                                    </Card>
                                </div>
                            </div>
                            </Card.Body>
                        </Card>
                        </CarouselItem>
                        </Carousel>
                    </div>
                   
                </div>
        )
    }
}

return (
    <div style={{ height: '100%' }}>
        {/* Main Layout */}
        <div style={{ height: '10%' }}>
            <Navigation />
        </div>
        <div style={{ height: '80%' }}>
            {handleMobile()}
        </div>
        <div style={{ height: '10%' }}>
            <Footer />
        </div>

        <ReportModal isOpen={showModal} onClose={handleCloseReportModal} postId={reportPostId}>
    <h2>Report Post?</h2>
    <p>Please tell us what was wrong with this post.</p>
    <input
      value={inputValue}
      onChange={handleInputChange}  // Local input change handler
      placeholder="Please Enter Complaint"
      style={{ width: '100%', marginBottom: '1rem', padding: '0.5rem' }}
    />
    {/* Centering the Submit Button */}
    <div style={{ display: 'flex', justifyContent: 'center' }}>
    <Button 
    onClick={() => {
        submitPostReportDetail(ownerId,reportPostId, reportPostUserId, complaint);
    }}
    style={{ backgroundColor: '#Ff9e28', color: 'black', width: '6rem',
             justifyContent: 'center', alignItems: 'center', borderColor: 'black' }}>
    Submit
</Button>

    </div>
</ReportModal>

    </div>
);

}