import React, { useState, useEffect } from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import debounce from 'lodash.debounce'; // Assuming lodash.debounce is installed
import { MDBContainer, MDBRow, MDBCol, MDBInput } from 'mdbreact';
import tasteMakersLogo from './Tastemakers Basic Logo.png';
import { useNavigate } from 'react-router-dom';
import { App } from '@capacitor/app';

export default function UserSearch() {
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    

    // Replace 'yourTokenVariable' with the actual token retrieval logic
    const token = sessionStorage.getItem('token'); 

    const navigate = useNavigate();
    useEffect(() => {
        const backButtonListener = App.addListener('backButton', (data) => {
            if (window.location.pathname === '/') {
                App.exitApp(); // Exit app if on the home page
            }else {
                  navigate(-1); // Go to the previous page
              } 
        });

        // Cleanup the listener when the component unmounts
        return () => {
            backButtonListener.remove();
        };
    }, [navigate]);
  

    const fetchSuggestions = async (query) => {
        if (!query) {
            setSuggestions([]);
            return;
        }

        // Adding quotation marks to the search term
        const quotedQuery = `"${query}"`;

        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL+ `/api/users/search/${quotedQuery}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const data = await response.json();
            setSuggestions(data);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        }
    };

    const debouncedFetchSuggestions = debounce(fetchSuggestions, 300); // 300ms delay

    useEffect(() => {
        debouncedFetchSuggestions(searchTerm);
    }, [searchTerm]);

    const handleUserClick = (userId) => {
        navigate("/User/" + userId, {state: {userId}})
    }

    return (
        <div style={{backgroundColor:'black'}}>
            <Navigation/>
            <MDBContainer style={{ height: '92vh' }}>
                <MDBRow className="d-flex justify-content-center align-items-center h-100">
                    <MDBCol md="6">
                        <MDBInput 
                            hint="Search" 
                            type="text" 
                            containerClass="active-pink active-pink-2 mt-0 mb-3" 
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                            {suggestions.map(user => {
                                const cleanUsername = user.userId.replace(/^"|"$/g, '');
                                return <div key={user.userId} style={{color:'white'}}>
                                    <img src={user.profileImageUrl || tasteMakersLogo} onClick={() => handleUserClick(user.userId)} style={{width:'4rem', height:'4rem', borderRadius:'2rem', marginRight:'1rem', outlineColor:'#Ff9e28', outlineWidth:'2rem',outline: '2px solid #Ff9e28'}}/>
                                    {cleanUsername}
                                    </div>;
                            })}
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <Footer/>
        </div>
    );
}
