import { useState, useEffect, useMemo, useCallback } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { Container, Row, Card, Button, Col, InputGroup, FormControl, CardGroup, Modal, ButtonGroup, CardDeck, Image, Carousel, CarouselItem, ModalDialog, ModalBody} from 'react-bootstrap';
import Navigation from './Navigation';
import Footer from './Footer';
import tasteMakersLogo from './Tastemakers Basic Logo.png'
import crown from './crown.png';
import { useNavigate } from 'react-router-dom';
import reportFlag from './reportFlag.png'
import ReportModal from './ReportModal'

import debounce from 'lodash.debounce';

import { App } from '@capacitor/app';

export default function UserProfile() {
    // Get the current URL
    const location = useLocation();
    const {userId} = location.state;

    const userEmail = sessionStorage.getItem("email")
    const [backendUser, setBackendUser] = useState([]);
    const [spotifyUser, setSpotifyUser] = useState([]);
    const [posts,setPosts] = useState([]);
    const [followerIds,setFollowersIds] = useState([]);
    const [followingIds,setFollowingIds] = useState([]);
    const [following, setFollowing] = useState([]);
    const [followers, setFollowers] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [complaint, setComplaint] = useState('');  // State to store input value
    const followingCount = useMemo(() => following.length, [following]);
    const followersCount = useMemo(() => followers.length, [followers]);
    const [isLiked, setIsLiked] = useState(false);
    const [likes, setLikes] = useState(0);
    const [userPro, setUserPro] = useState(false);
    const [userImg, setUserImg] = useState('');
    const [userBio, setUserBio] = useState('');
    const [isFollowing, setIsFollowing] = useState(false);
    const [profileUserId, setProfileUserId] = useState('');
    const [userTitle, setUserTitle] = useState('');
    const [privacyBool, setPrivacyBool] = useState(false)
    const [notificationId, setNotificationId] = useState('');
    const navigate = useNavigate();

    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
      }, []);

      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  
      useEffect(() => {
  
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        };
      
        window.addEventListener("resize", handleResize);
        handleResize(); // Set initial size on mount
      
        return () => window.removeEventListener("resize", handleResize);
        
      }, []);
      useEffect(() => {
        const backButtonListener = App.addListener('backButton', (data) => {
            if (window.location.pathname === '/') {
                App.exitApp(); // Exit app if on the home page
            }else {
                  navigate(-1); // Go to the previous page
              } 
        });

        // Cleanup the listener when the component unmounts
        return () => {
            backButtonListener.remove();
        };
    }, [navigate]);

      useEffect(() => {
        async function fetchData() {
          try {
            // Fetching register user data
            const registerResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/register/${userEmail}`);
            if (!registerResponse.ok) {
              throw new Error('Failed to fetch register user');
            }
            const registerData = await registerResponse.json();
            setSpotifyUser(registerData);
      
            // Fetching Spotify user data
            const spotifyResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + '\"' + registerData.userName + '\"', {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
      
            if (!spotifyResponse.ok) {
              throw new Error('Failed to fetch Spotify user');
            }
            const spotifyUserData = await spotifyResponse.json();
            setBackendUser(spotifyUserData);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
      
        fetchData();
      }, []);
      
      useEffect(() => {
        if (!backendUser || !backendUser.userId) return;
      
        async function runUser() {
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/${userId}`,{
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
            const data = await response.json();
            setUserPro(data.paidMember);
            setFollowing(data.following);
            setFollowers(data.followers);
            setUserImg(data.profileImageUrl);
            setProfileUserId(data._id);
            setUserTitle(data.userTitle);
            setPrivacyBool(data.privateProfile);
            setNotificationId(data.notificationId);
            setUserBio(data.userBio)
          } catch (error) {
            console.error('Error:', error);
          }
        }
      
        runUser();
      }, [backendUser]);
      
      useEffect(() => {
        if (!backendUser || !backendUser.userId) return;
      
        async function getUserPosts() {
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/getAllUserPosts/${userId}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
            const data = await response.json();
            setPosts(data);
          } catch (error) {
            console.error('Error fetching user posts:', error);
          }
        }
      
        getUserPosts();
      }, [backendUser, likes, isLiked]);
      
      useEffect(() => {
        if (!backendUser || !backendUser.userId) return;
      
        const fetchDetails = async (userIds) => {
          return Promise.all(
            userIds.map(async (userId) => {
              try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/getUserById/${userId}`, {
                  method: 'GET',
                  headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
                });
                const data = await response.json();
                return {
                  userId: data.userId, 
                  following: data.following, 
                  followers: data.followers, 
                  userTitle: data.userTitle,
                  profileImageUrl: data.profileImageUrl,
                  userBio: data.userBio
                  // other details if needed
                };
              } catch (error) {
                console.error('Error fetching user details:', error);
                return null;
              }
            })
          );
        };
      
        const updateDetails = async () => {
          if (followers.length > 0) {
            const followerDetails = await fetchDetails(followers);
            setFollowersIds(followerDetails.filter(detail => detail !== null));
          }
      
          if (following.length > 0) {
            const followingDetails = await fetchDetails(following);
            setFollowingIds(followingDetails.filter(detail => detail !== null));
          }
        };
      
        updateDetails();
      }, [backendUser, followers, following, isFollowing]);
      
      useEffect(() => {
        //console.log('Followers:', followers);
    
        if (!Array.isArray(followers) || followers.length === 0) {
            //console.log('No followers or invalid data structure');
            return;
        }
    
        if (!backendUser || !backendUser._id) {
            //console.log('Backend user data not loaded or invalid');
            return;
        }
    
        followers.forEach(follower => {
            if (follower && follower._id) {
                //console.log("Array Id:" + follower._id);
            }
        });
    
        const followingStatus = followers.some(follower => follower === backendUser._id);
        //console.log(backendUser._id)
        //console.log('Following Status:', followingStatus);
        setIsFollowing(followingStatus);
    
    }, [followers, backendUser]); // Make sure backendUser is also a dependency
    
    useEffect(() => {

    },[followersCount, isFollowing])

      function handleCardPress(postId,userId){
        navigate('/User/' + userId + '/Post/' + postId, {
          state: {userId, postId}
      })
    }

    // frontend/src/yourComponent.js
    const [showModal, setShowModal] = useState(false);
    const [reportedUserId,setReportedUserId] = useState('');
    const handleReportUser = async (userId) =>{
      setReportedUserId(userId)
      handleOpenReportModal()
  }

    const handleOpenReportModal = () => {
        setShowModal(true);
    };
    const updateComplaint = useCallback(
      debounce((value) => {
          setComplaint(value);
      }, 300), []  // The empty dependency array ensures that the debounce function is memoized and doesn't recreate on every render.
  );

    const handleCloseReportModal = () => {
        setComplaint('')
        setInputValue('')
        setShowModal(false);
    };

    const submitUserReportDetail = async (userId, reportedUserId, complaint) => {
        handleCloseReportModal();  // Close the modal
        alert(userId + " " +reportedUserId + " " + complaint)
        try {
          const reportResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/reportUser', {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: userId,
                reportedUserId: reportedUserId,
                message: complaint
            })
        });
        
        // Check for network errors
        if (!reportResponse.ok) {
            // Try to parse the response as JSON
            let errorMessage = 'An error occurred';  // Default error message
        
            try {
                const errorResponse = await reportResponse.json();  // Parse response as JSON
                if (errorResponse.error) {
                    errorMessage = errorResponse.error;  // Extract the specific error message
                } else {
                    errorMessage = JSON.stringify(errorResponse);  // If no specific error, show the full response
                }
            } catch (err) {
                // If the response isn't valid JSON, fallback to plain text
                errorMessage = await reportResponse.text();  // Extract the plain text response
            }
        
            console.error('Error Response:', errorMessage);
            alert(errorMessage);
            return;
        }
        
    
            // Handle successful report
            alert("Post reported successfully.");
        } catch (error) {
            // Catch network errors or issues with fetch
            console.error('Fetch error:', error);
            alert('Failed to fetch: ' + error.message);
        }
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);  // Update the local input value immediately
        updateComplaint(value);  // Use debounced function to update the complaint
      };
      useEffect(() => {
        return () => {
          updateComplaint.cancel();  // Cleanup the debounce function on component unmount
        };
      }, []);
async function handleFollowNotification(userId, followerName) {
    alert("onesignal code reached");
    alert("ID: " + process.env.REACT_APP_ONE_SIGNAL_APP_ID); // This can be removed as it's now handled by the backend
    alert("Key: " + process.env.REACT_APP_ONE_SIGNAL_API_KEY); // Remove this too for security

    if (!userId) {
        alert("No device ID found for user.");
        return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/send-notification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // No need to send Authorization here as the backend handles it
            },
            body: JSON.stringify({ userId, followerName }),
        });

        if (response.ok) {
            const result = await response.json();
            alert('Notification sent successfully: ' + JSON.stringify(result));
            navigate(0)
        } else {
            const errorData = await response.json();
            alert('Failed to send notification: ' + errorData.error + ' - ' + (errorData.details || ''));
        }
    } catch (error) {
        alert('Error sending notification: ' + error.message);
    }
}

function handleHCardHeight(message, image){
  var returnVal = null;
  if(message.length > 100 && image == null){
    returnVal = "20.5rem"
  }else if(message.length < 100 && message.length < 25 && image){
      returnVal = "29rem"
  }else if(message.length > 10 && image){
      returnVal = "30rem"
  }else{
    returnVal = "13rem"
  }
  return returnVal;
}
    

    function privateProfileHandle() {
      // console.log(followerIds);
  
      // Extract userIds from the followerIds array
      const followerUserIds = followerIds.map(follower => follower.userId);
  
      // console.log(followerUserIds);
      if (followerUserIds.includes(backendUser.userId) || privacyBool == false) {
          return(
<Carousel defaultActiveIndex={1} interval={null} style={{width: '100%'}}>
        <CarouselItem interval={null}>
        <style>
        {`
          .carousel-control-prev,
          .carousel-control-next {
            display: none;
          }
        `}
      </style>
      <div style={{ height: '85vh', color: 'white' }}>
    <h1 style={{ textAlign: 'center', color: 'white', marginBottom: '2rem', marginTop: '2rem' }}>Following</h1>
    <Col style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', overflowY: 'auto' }}>
            {followingIds != null &&
                followingIds.map((follower) => {
                    return (
                        <Card
                            key={follower._id}
                            style={{
                                borderColor: '#Ff9e28',
                                display: 'inline-block',
                                alignItems: 'center',
                                justifyContent: 'center',
                                justifyItems: 'center',
                                width: isMobile ? '90vw' : '60%',
                                height: '6rem',
                                backgroundColor: 'black',
                                color: 'white',
                                marginBottom: '1rem',
                            }}
                        >
                            <CardGroup style={{ display: 'flex', alignItems: 'center' }}>
                                <Card.Img
                                    src={follower.profileImageUrl || tasteMakersLogo}
                                    style={{ borderRadius: '50%', marginTop: '.5rem', width: '5rem', height: '5rem' }}
                                />
                                <Card style={{ backgroundColor: 'black', color: 'white', flex: 1 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <Card.Text style={{ marginLeft: '2rem', marginTop: '1rem', marginBottom: '0rem' }}>
                                                @{follower.userId.replace(/^"|"$/g, '')}
                                            </Card.Text>
                                            <Card.Text style={{ marginLeft: '2rem' }}>
                                                <span style={{ color: 'white' }}>Philos </span>
                                                <span
                                                    style={{
                                                        color:
                                                            follower.userTitle === 'Basic'
                                                                ? 'black'
                                                                : follower.userTitle === 'Seer'
                                                                ? 'cyan'
                                                                : follower.userTitle === 'Certified'
                                                                ? 'gold'
                                                                : follower.userTitle === 'Pro'
                                                                ? '#Ff9e28'
                                                                : follower.userTitle === 'Prime'
                                                                ? 'red'
                                                                : 'black',
                                                    }}
                                                >
                                                    {follower.userTitle}
                                                </span>
                                            </Card.Text>
                                        </div>
                                        <Button
                                            style={{
                                                color: '#Ff9e28',
                                                backgroundColor: 'black',
                                                borderColor: 'black',
                                                marginTop:'1rem'
                                            }}
                                            onClick={() => alert("pressed report")}
                                        >
                                            <img
            src={reportFlag}
            />
                                        </Button>
                                    </div>
                                </Card>
                            </CardGroup>
                        </Card>
                    );
                })}
        </Row>
    </Col>
</div>

                    </CarouselItem>
                    <CarouselItem>
    <div style={{ marginTop:'2rem',height: '85vh', width: '100%', position: 'relative' }}>
        <h1 style={{
            position: 'sticky',
            top: '0',
            left: '0',
            width: '100%',
            textAlign: 'center',
            margin: '0',
            padding: '1rem 0',
            backgroundColor: 'black',
            color: 'white',
            zIndex: '1000',
            borderBottom: '2px solid #Ff9e28'
        }}>
            Posts
        </h1>
        <div style={{
            paddingTop: '4rem',
            height: 'calc(85vh - 4rem)',
            overflowY: 'auto',
            backgroundColor: 'black',
            color: 'white'
        }}>
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                <Row className="flex" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    scrollbarColor: '#Ff9e28 black',
                    scrollbarWidth: 'thin',
                    WebkitOverflowScrolling: 'touch',
                    width: '100%',
                }}>
                    {posts.length != null &&
                    posts.filter(post => post.userId === userId).slice().reverse().map(post => (
                        <Card key={post._id} style={{
                            outlineColor: '#Ff9e28',
                            borderColor: '#Ff9e28',
                            display: 'inline-block',
                            width: '96%',
                            height: handleHCardHeight(post.message,post.postImageUrl),
                            backgroundColor: 'black',
                            color: 'white',
                            marginBottom: '1rem'
                        }} onClick={() => handleCardPress(post._id, post.ownerId)}>
                            <CardGroup style={{ display: 'flex' }}>
                                <Card.Img src={post.profileImageUrl || tasteMakersLogo} style={{
                                    borderRadius: '50%',
                                    marginTop: '1rem',
                                    width: '5rem',
                                    height: '5rem'
                                }} />
                                <Card style={{ backgroundColor: 'black', color: 'white' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <Card.Title style={{ marginLeft: '1rem', marginTop: '2rem', marginBottom: '0rem' }}>
                                                @{post.userId.replace(/^"|"$/g, '')}
                                            </Card.Title>
                                            <Card.Text style={{ marginLeft: '1rem' }}>
                                                <span style={{ color: 'white' }}>Philos </span>
                                                <span style={{
                                                    color:
                                                        post.userTitle === 'Basic' ? 'black' :
                                                            post.userTitle === 'Seer' ? 'cyan' :
                                                                post.userTitle === 'Certified' ? 'gold' :
                                                                    post.userTitle === 'Pro' ? '#Ff9e28' :
                                                                        post.userTitle === 'Prime' ? 'red' :
                                                                            'black'
                                                }}>{post.userTitle}</span>
                                            </Card.Text>
                                        </div>
                                    </div>
                                </Card>
                            </CardGroup>
                            <Card style={{ color: 'white', backgroundColor: 'black' }}>
                                <Card.Body>{post.message}</Card.Body>
                                {post.postImageUrl && (
                                  <Card.Img 
                                      style={{
                                          width: '22rem',
                                          height: '15rem',
                                          marginBottom: '1rem',
                                          display: 'block',  // Ensures the image is treated as a block-level element
                                          marginLeft: 'auto',  // Centers the image horizontally
                                          marginRight: 'auto'  // Centers the image horizontally
                                      }} 
                                      src={post.postImageUrl}
                                  />
                              )}
                                <Card.Text style={{ color: 'white', justifyContent: "right", display: 'flex' }}>
                                    <Button style={{
                                        color: "#Ff9e28",
                                        backgroundColor: "black",
                                        borderColor: "#Ff9e28",
                                        marginRight: '1rem'
                                    }} onClick={(e) => { e.stopPropagation(); likeOrUnlikePost(post._id, backendUser.userId) }}>
                                        {post.likes.length} Likes
                                    </Button>
                                    <Button style={{
                                        color: "#Ff9e28",
                                        backgroundColor: "black",
                                        borderColor: "#Ff9e28"
                                    }}>
                                        {post.comments.length} Comments
                                    </Button>
                                </Card.Text>
                            </Card>
                        </Card>
                    ))}
                </Row>
            </Col>
        </div>
    </div>
</CarouselItem>


                    <CarouselItem>
                    <div style={{ marginTop: '2rem', height: '85vh', color: 'white', marginBottom: '8rem' }}>
    <h1 style={{ textAlign: 'center', color: 'white', marginBottom: '0rem' }}>Followers</h1>
    <Col
        style={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
        }}
    >
        <Row
            className="flex"
            style={{
                scrollbarColor: '#Ff9e28 black',
                scrollbarWidth: 'thin',
                WebkitOverflowScrolling: 'touch',
                height: '85vh',
                width: '100%',
                overflowX: 'hidden',
                overflowY: 'auto',
                marginTop: '1rem',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            {followerIds != null &&
                followerIds.map((follower) => {
                    return (
                        <Card
                            key={follower._id}
                            style={{
                                marginBottom: '1rem',
                                borderColor: '#Ff9e28',
                                display: 'inline-block',
                                width: isMobile ? '90vw' : '60%', // Same as Following cards
                                height: '6rem', // Same height as Following cards
                                backgroundColor: 'black',
                                color: 'white',
                            }}
                        >
                            <CardGroup style={{ display: 'flex', alignItems: 'center' }}>
                                <Card.Img
                                    src={follower.profileImageUrl || tasteMakersLogo}
                                    style={{ borderRadius: '50%', marginTop: '.5rem', width: '5rem', height: '5rem' }}
                                />
                                <Card style={{ backgroundColor: 'black', color: 'white', flex: 1 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <Card.Text style={{ marginLeft: '2rem', marginTop: '1rem', marginBottom: '0rem' }}>
                                                @{follower.userId.replace(/^"|"$/g, '')}
                                            </Card.Text>
                                            <Card.Text style={{ marginLeft: '2rem' }}>
                                                <span style={{ color: 'white' }}>Philos </span>
                                                <span
                                                    style={{
                                                        color:
                                                            follower.userTitle === 'Basic'
                                                                ? 'black'
                                                                : follower.userTitle === 'Seer'
                                                                ? 'cyan'
                                                                : follower.userTitle === 'Certified'
                                                                ? 'gold'
                                                                : follower.userTitle === 'Pro'
                                                                ? '#Ff9e28'
                                                                : follower.userTitle === 'Prime'
                                                                ? 'red'
                                                                : 'black',
                                                    }}
                                                >
                                                    {follower.userTitle}
                                                </span>
                                            </Card.Text>
                                        </div>
                                        <Button
                                            style={{
                                                width: '2.5rem',
                                                height: '2.5rem',
                                                color: '#Ff9e28',
                                                backgroundColor: 'black',
                                                borderColor: 'black',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img src={reportFlag} alt="Report" />
                                        </Button>
                                    </div>
                                </Card>
                            </CardGroup>
                        </Card>
                    );
                })}
        </Row>
    </Col>
</div>

                    </CarouselItem>
                    </Carousel>
          )
      } else {
        return(
        <div style={{color:'white'}}>
          <h1 style={{color:'white'}}>Private Profile</h1>
          <h2> Request to Follow this User to See Content</h2>
        </div>)
      }
  }  

    const likeOrUnlikePost = async (postId, userId) => {
        try {
            // Fetch user details to get realId
            const userRealIdResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + userId ,{
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
            const userData = await userRealIdResponse.json();

            if (!userData || !userData._id) {
                console.error('User data or user ID not found');
                return;
            }

            const realId = userData._id;
            //console.log('Real ID:', realId);

            // Fetch post details to check if realId is in the likePost array
            const postResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/getPost/' + postId,{
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
            const postData = await postResponse.json();

            const likePostArray = postData.likes || [];
            const isLiked = likePostArray.some(like => like.userId === realId);

            if (isLiked) {
                // If already liked, unlike the post
                const unlikeResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/unLikePost/' + postId + '/user/' + realId, {
                  method: 'DELETE',
                  headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
                });
                const unlikeResult = await unlikeResponse.json();
                //console.log(unlikeResult);
            } else {
                // If not liked, like the post
                const likeResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/likePost/' + postId + '/user/' + realId, {
                  method: 'POST',
                  headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
                });
                const likeResult = await likeResponse.json();
                //console.log(likeResult);
            }

            // Update the state to trigger a re-render
            setIsLiked((prevIsLiked) => !prevIsLiked);

            setLikes(likePostArray.length);
        } catch (error) {
            // Handle errors
            // console.error('Error:', error);
        }
    };

    const handleFollowButton = async () => {
        alert("test block");
        
        try {
            let response;
    
            if (isFollowing) { 
                // Unfollow user logic
                response = await unfollowUser(backendUser._id, profileUserId, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                        'Content-Type': 'application/json',
                    },
                });
            } else {
                // Follow user logic
                response = await followUser(backendUser._id, profileUserId, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())  // Parse JSON from the response
                .then(data => {
                    alert(JSON.stringify(data));  // Alert the JSON response data
                    alert("Sending follow notification");
                    handleFollowNotification(notificationId, data.userId);
                });
            }
    
            // Check if the response is successful (HTTP status 200)
            if (response.status === 200) {
                // Update the state only on successful response
                alert("Following");
                setIsFollowing(!isFollowing);
    
                // Trigger notification if user is followed
                // if (!isFollowing) {
                //     alert("Sending follow notification");
                //     handleFollowNotification(notificationId, userId);
                // }
            } else {
                throw new Error('Failed to update follow status');
            }
    
        } catch (error) {
            console.error('Error updating follow status:', error);
        }
    
        // Refresh or redirect logic
        navigate(0);
    };    
  
  const unfollowUser = async (userId, friendId) => {
    try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/friends/deleteFollow', {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, friendId })
        });

        //console.log("Unfollow response:", await response.json()); // Log the response body
        return response;
    } catch (error) {
        console.error('Unfollow error:', error);
        throw error; // Rethrow to handle it in the calling function
    }
};

function isTheUser (){
  if(userId == backendUser.userId){
    navigate('/Account');
  }
}

const followUser = async (userId, friendId) => {
    try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/friends/addFollow', {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, friendId })
        });

        //console.log("Follow response:", await response.json()); // Log the response body
        return response;
    } catch (error) {
        console.error('Follow error:', error);
        throw error; // Rethrow to handle it in the calling function
    }
};
  

    function handleProUser(){
        if(userPro == true){
          return(
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
              <h1 style={{ fontSize:'2.5rem',margin: '0', display: 'flex', alignItems: 'center', marginLeft:'2rem' }}>
                <img alt='' style={{ width: '2.3rem', height: '2.3rem', marginRight: '.5rem' }} src={crown} />
                {userId.replace(/^"|"$/g, '')}
              </h1>
              <Button 
              onClick={() => handleReportUser(profileUserId)}
              style={{ backgroundColor:"black", borderColor:"black", borderRadius:"25px"}
            }><img
            src={reportFlag}
            /></Button>
            </div>
          )
        }else{
            return(
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
                <h1 style={{marginTop:'1rem'}}>{userId.replace(/^"|"$/g, '')}</h1>
                
              <Button 
              onClick={() => handleReportUser(profileUserId)}
              style={{ backgroundColor:"black", borderColor:"black", borderRadius:"25px"}
            }><img
            src={reportFlag}
            /></Button>

            </div>
                )
        }
      }

    const isMobile = windowSize.width < 765;

    return( 
        <div>
            <Navigation/>
            <div style={{height:'100%', width:'100%', backgroundColor:'black', display: isMobile ? 'block' : 'flex', color:'white', alignContent:'center',justifyContent: "center", alignItems:'center'}}>
                <div style={{ height:'70vh',alignContent:'center',justifyContent: "center", alignItems:'center' , textAlign: "center",  width:isMobile ? '100%' : '50%' }}>
                    <div style={{width:"100%"}}>  
                    <div style={{height: isMobile ? '100vh' : '80vh', width: '100%', backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}>
    <div style={{textAlign: 'center',height:"100%", width: isMobile ? '100%' : '50%'}}>
        <img src={userImg || tasteMakersLogo} alt='...' style={{
          borderColor:'#Ff9e28', // Border color
          borderWidth: '2px',    // Border width
          borderStyle: 'solid',  // Border style
          width: '20rem', borderRadius: '10rem', height: '20rem', margin: 'auto', marginTop:"1rem"}}/>
                     {handleProUser()}
                     {isTheUser()}
                     <h2 style={{ marginTop:".5rem",fontSize:'1.5rem'// default color if none of the conditions are met
                        }}>
                        <span style={{color:'white'}}>Philos </span><span style={{  color:
                            userTitle === 'Seer' ? 'blue' :
                            userTitle === 'Certified' ? 'gold' :
                            userTitle === 'Pro' ? '#Ff9e28' :
                            userTitle === 'Prime'? 'red':
                            'white'
                            }}>{userTitle}</span>
                        </h2>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h5 
                          style={{
                            width: "18rem",
                            marginTop:".5rem",
                            textAlign: 'center',
                            whiteSpace: 'pre-wrap',   // Preserve line breaks
                            display: '-webkit-box',   // Display as a flex container (for line clamping)
                            WebkitLineClamp: 4,       // Limit the number of lines to 4
                            WebkitBoxOrient: 'vertical', // Specify vertical box orientation
                            overflow: 'hidden',       // Hide any overflowing content
                            textOverflow: 'ellipsis', // Optional: Adds "..." at the end if truncated
                          }}
                        >
                          {userBio}
                        </h5>
                    </div>
                     <div style={{ display: "flex", justifyContent: "center", marginTop:'1rem' }}>
                        <div style={{ margin: "0 1rem" }}>
                        <h5>Following</h5>
                        <h5>{followingCount}</h5>
                        </div>
                        <div style={{ margin: "0 1rem" }}>
                        <h5>Followers</h5>
                        <h5>{followersCount}</h5>
                        </div>
                    </div>
                    <div style={{textAlign:"center", marginTop:"1rem", marginBottom:"1rem"}}>
                                <Button style={{color:'#Ff9e28', backgroundColor:'black', borderColor:'#Ff9e28', marginBottom:'2rem'}}onClick={() => handleFollowButton()}>
                                {isFollowing ? 'Following' : 'Follow'}
                                </Button>
                            </div>
                </div>
                            
                        </div>
                    </div> 
                </div>
                <div style={{width: isMobile ? '100%' : '50%', display: 'flex', justifyContent: 'center'}}>
                {privateProfileHandle()}
                </div>
            </div>
            <Footer/>
            <ReportModal isOpen={showModal} onClose={handleCloseReportModal} postId={reportedUserId}>
    <h2>Report User?</h2>
    <p>Please tell us what is wrong with this User.</p>
    <input
      value={inputValue}
      onChange={handleInputChange}  // Local input change handler
      placeholder="Please Enter Complaint"
      style={{ width: '100%', marginBottom: '1rem', padding: '0.5rem' }}
    />
    {/* Centering the Submit Button */}
    <div style={{ display: 'flex', justifyContent: 'center' }}>
    <Button 
    onClick={() => {
        submitUserReportDetail(backendUser._id,reportedUserId, complaint);
    }}
    style={{ backgroundColor: '#Ff9e28', color: 'black', width: '6rem',
             justifyContent: 'center', alignItems: 'center', borderColor: 'black' }}>
    Submit
</Button>

    </div>
</ReportModal>
        </div>
    );
}
