import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import mainLogo from '../src/Tastemakers Basic Logo.png'

export default function Footer() {
  return (
    <div style={{backgroundColor:"black"}}>
    <MDBFooter bgColor='black' className='text-center text-lg-start text-muted'>

      <div className='text-center p-4' style={{ backgroundColor: '#ff914d' }}>
        © 2023 Copyright:    
        <a className='text-reset fw-bold' href={process.env.REACT_APP_FRONTEND_URL+'/'}>
          Ergo Media LLC
        </a>

      </div>
    </MDBFooter>
    </div>
  );
}