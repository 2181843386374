import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, InputGroup, FormControl, Button, Row, Card, CardGroup, Form } from 'react-bootstrap';
import React, { useState, useEffect, Component } from 'react';
import TastemakerImg from './Tastemakers Basic Logo.png'
import Footer from './Footer Desktop Login'
import FooterMobile from './Footer Login'
import { useUser } from './UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { Input, Typography } from "@material-tailwind/react";
import { useParams } from 'react-router-dom';

export default function Login({notificationId}) {
  useEffect(() => {
    if (notificationId) {
        console.log("Received notification ID:", notificationId);
    }
}, [notificationId]);

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  }, []);
  

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [backendUser, setBackendUser] = useState([]);
  const [spotifyUser, setSpotifyUser] = useState([]);
  const [backendId, setBackendId] = useState([]);
  const { setUser } = useUser();
  const [responseMessage, setResponseMessage] = useState('');
  const navigate = useNavigate();

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const query = useQuery();

  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

//   const handleUserInfo = async () => {
//     try {
//       const registerResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/register/${email}`);
//       if (!registerResponse.ok) {
//         throw new Error('Failed to fetch register user');
//       }
//       const registerData = await registerResponse.json();
//       setSpotifyUser(registerData);
 
//       const spotifyResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + '\"' + registerData.userName + '\"', {
//         method: 'GET',
//         headers: {
//           'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
//           'Content-Type': 'application/json',
//         },
//       });
 
//       if (!spotifyResponse.ok) {
//         throw new Error('Failed to fetch Spotify user');
//       } 
//       const spotifyUserData = await spotifyResponse.json();
//       setBackendId(spotifyUserData._id);
 
//       // Return the backendId once set
//       return spotifyUserData._id;
 
//     } catch (error) {
//       console.error("Error fetching user info", error);
//     }
//   };
 

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    document.body.style.overflowX = 'hidden';

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const location = useLocation();
  const containerStyle = {
    height: "5rem", backgroundSize: "0", backgroundColor: "black", bottom: "0",
    position: "fixed", zIndex: "1060", width: "100%", color: "white", display: "flex"
  };

    function handleRegister(){
    navigate("/Register")
  }
  const isMobile = windowSize.width < 765
  const { token } = useParams()

  const activateAccount = async () => {
    
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/login/activate/${token}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        });
        console.log(response)
        // Checking response object for success or failure
        if (response.ok) {
            const data = await response.json();
            setResponseMessage(data.message);
        }else if(response.status == 408){
            const data = await response.json();
            setResponseMessage(data.message)
        } else if(response.status == 407){
            const data = await response.json();
            setResponseMessage(data.message)
        } 
        else {
            const errorData = await response.json();
            setResponseMessage("Failed to Activate Account: " + errorData.message);
        }
    } catch (e) {
        console.error("Error activating account:", e);  // Log the error for debugging
        setResponseMessage("An error occurred while activating the account.");
    }
};


//   const updateNotificationId = async (notificationId) => {
//     try {
//       const userBackendId = await handleUserInfo(); // Wait for the backendId from handleUserInfo
//       alert("id:" + userBackendId)
//       // Proceed with the fetch request using the obtained backendId
//       const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/update/' + userBackendId, {
//         method: 'PATCH',
//         body: JSON.stringify({
//           notificationId: notificationId
//         }),
//         headers: {
//           'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
//           'Content-Type': 'application/json',
//         },
//       });
  
//       if (response.ok) {
//         alert("NotificationId updated for User");
//       } else {
//         alert("Failed to update Notification ID");
//       }
//     } catch (error) {
//       alert("Failed to Update Notification ID: " + error);
//     }
//   };
  
  const isCapacitor = typeof window !== "undefined" && !!window.Capacitor;
  const isMobilePhone = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isBrowser = typeof window !== "undefined" && !isCapacitor && !isMobile;
  
  function handleSmallerScreen() {
    activateAccount()
    if(isMobilePhone){
      return (
        <div style={{ height: "100vh", width: "100%", display: "inline-block" }}>
          <div style={{ height: "100%", width: "100%", backgroundColor: 'black', display: isMobile ? "inline-block" : "flex" }}>
          <div style={{ width: isMobile ? "100%" : "50%", height: isMobile ? "70%" : "100%",display: isMobile ? "inline-block" : "flex", justifyContent:'center', alignContent:'center', alignItems: 'center', justifyItems:"center" }}>
            {isMobile ? "" : 
                 <img src={TastemakerImg} style={{ width: isMobile ? "65%" : "100%", height: "auto" }} />
              }
            <div style={{ display: "flex", flexDirection: "column", marginLeft: "1rem", justifyContent:'center', alignContent:'center', alignItems: 'center', justifyItems:"center" }}>
              {isMobile ? <img src={TastemakerImg} style={{ width: isMobile ? "65%" : "100%", height: "auto" }} /> : 
                 ""
              }
              <h1 style={{
                color: '#Ff9e28',
                fontFamily: 'Noto Serif Oriya, serif',
                fontSize: '5rem',
                background: `-webkit-linear-gradient(45deg, #ff4500, #ffa500)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: isMobile ? '5rem' : '6rem'
              }}>Ergo</h1>
              { isMobile ? "" :
              <h2 style={{ fontSize: "1.5rem", color:'dark#Ff9e28' }}>Get Paid to Engage</h2>
              }
            </div>
          </div>

            <div style={{ width: "100%", height: isMobile ? "30%" : "", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "1rem" }}>
              <h1 style={{marginBottom: isMobile ? '1rem' : "",color: '#Ff9e28',background: `-webkit-linear-gradient(45deg, #ff4500, #ffa500)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent', }}>{isMobile ? "" : "Log In"}</h1>
              {/* <input
                style={{ marginBottom: '1rem' }}
                type="text"
                placeholder="Email"
                className="bg-transparent text-white rounded px-3 py-2 outline-none focus:ring-2 focus:ring-blue-500"
                value={email}
                onChange={handleEmailChange}
              />
              <input
                type="password"
                placeholder="Password"
                className="bg-transparent text-white rounded px-3 py-2 outline-none focus:ring-2 focus:ring-blue-500"
                value={password}
                onChange={handlePasswordChange}
              /> */}
              {/* <Button style={{color: '#Ff9e28', backgroundColor:'black', borderColor:'#Ff9e28', marginTop:'1rem', marginBottom:'1rem'}} onClick={handleLogin}>Login</Button>
              <Button style={{textDecoration:'underline',color: '#Ff9e28', backgroundColor:'black', borderColor:'black'}} onClick={handleRegister}>Register</Button> */}
            </div>
          </div>
        </div>
      )
    }else{
      return (
        <div style={{ height: "100vh", width: "100%", display: "inline-block" }}>
          <div style={{ height: "100%", width: "100%", backgroundColor: 'black', display: isMobile ? "inline-block" : "flex" }}>
          <div style={{ width: isMobile ? "100%" : "50%", height: isMobile ? "70%" : "100%",display: isMobile ? "inline-block" : "flex", justifyContent:'center', alignContent:'center', alignItems: 'center', justifyItems:"center" }}>
            {isMobile ? "" : 
                 <img src={TastemakerImg} style={{ width: isMobile ? "65%" : "100%", height: "auto" }} />
              }
            <div style={{ display: "flex", flexDirection: "column", marginLeft: "1rem", justifyContent:'center', alignContent:'center', alignItems: 'center', justifyItems:"center" }}>
              {isMobile ? <img src={TastemakerImg} style={{ width: isMobile ? "65%" : "100%", height: "auto" }} /> : 
                 ""
              }
              <h1 style={{
                color: '#Ff9e28',
                fontFamily: 'Noto Serif Oriya, serif',
                fontSize: '5rem',
                background: `-webkit-linear-gradient(45deg, #ff4500, #ffa500)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                marginBottom:'1rem',
                fontSize: isMobile ? '5rem' : '6rem'
              }}>Ergo</h1>
              {/* { isMobile ? 
              <div>
                <button
                title='Google Play Store'
                >Google Play Store</button>
                <button
                title='App Store'
                >App Store</button>
              </div>
              :
              <h2 style={{ fontSize: "1.5rem", color:'dark#Ff9e28' }}>Get Paid to Engage</h2>
              } */}
            </div>
          </div>

            <div style={{ width: "100%", height: isMobile ? "30%" : "", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "1rem" }}>
              <h1 style={{marginBottom: isMobile ? '1rem' : "",color: '#Ff9e28',background: `-webkit-linear-gradient(45deg, #ff4500, #ffa500)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent' }}>Activate Account</h1>
            <h2 style={{marginBottom: isMobile ? '1rem' : "",color: '#Ff9e28',background: `-webkit-linear-gradient(45deg, #ff4500, #ffa500)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent' }}>{responseMessage}</h2>
                
            {/* // responseMessage.match("Invalid activation token") ? ( */}
                <Button style={{width:'8rem',height:'3.5rem'}}>Download App</Button>
            {/* // ) : (
            //     <></> // This renders nothing if the condition is false
            // )
            // } */}
            </div>
            
          </div>
        </div>
      )
    }
  }

  return (
    <div>
      {handleSmallerScreen()}
      
      <Footer />
    </div>
  )
}