import React from 'react';

function ReportModal({ isOpen, onClose, postId, children }) {
  if (!isOpen) return null;  // Modal will not render unless isOpen is true

  return (
    <div className="modal" style={modalBackdropStyles}>
      <div className="modal-content" style={modalContentStyles}>
        <div style={modalHeaderStyles}>
          <button onClick={onClose} style={closeButtonStyles}>Close</button>
        </div>
        {children}
      </div>
    </div>
  );
}

// Styles for the modal backdrop
const modalBackdropStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent backdrop
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000, // Make sure it's above other elements
};

// Styles for the modal content
const modalContentStyles = {
  backgroundColor: 'white',
  padding: '2rem',
  borderRadius: '8px',
  width: '300px',  // You can adjust the size as needed
  textAlign: 'center',
  position: 'relative',
};

// Styles for the modal header (where the close button is placed)
const modalHeaderStyles = {
  display: 'flex',
  justifyContent: 'flex-end',  // Align the close button to the right
};

// Styles for the close button
const closeButtonStyles = {
  backgroundColor: 'black',
  borderColor:'#Ff9e28',
  color: '#Ff9e28',
  border: 'none',
  padding: '0.5rem 1rem',
  borderRadius: '5px',
  cursor: 'pointer',
  marginBottom:'1rem'
};

export default ReportModal;
